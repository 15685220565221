import { Component, Injector, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { ConfigStateService } from '@abp/ng.core';
import { AppComponentBase } from './shared/app-component-base';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppUserService } from '@proxy/users';

@Component({
  selector: 'app-root',
  template: `
   <router-outlet></router-outlet>
  `,
})
export class AppComponent extends AppComponentBase implements OnInit {
  private hubLogoutConnection: HubConnection;

  topbarTheme = 'light';

  menuTheme = 'dim';

  layoutMode = 'light';

  menuMode = 'static';

  isRTL = false;

  inputStyle = 'outlined';

  ripple: boolean;
  constructor(
    injector: Injector,
    private primengConfig: PrimeNGConfig,
    private _configState: ConfigStateService,
    private _router: Router,
    private route: ActivatedRoute,
    private _userService: AppUserService
  ) {
    super(injector);
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({
      emptyMessage: this.L('::Common:NoData'),
    });
    this._startHubConnection();

    const currentUrl = localStorage.getItem('currentUrl');
    if (currentUrl) {
      localStorage.removeItem('currentUrl');
      this._router.navigateByUrl(currentUrl);
    }

    this.route.queryParams.subscribe((params) => {
      const iss = params['iss'];
      if (iss) {
        // Nếu có tham số iss, thực hiện điều hướng về URL mong muốn (http://localhost:4200)
        this._router.navigate(['/']);
      }
    });

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // const rootElement = document.documentElement || document.body;
        // rootElement.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
        if (!this.hasLoggedIn && (event.urlAfterRedirects === '/home' || event.urlAfterRedirects === '/')) {
          this._router.navigateByUrl('/home');
        }
        else if (this.hasLoggedIn && (event.urlAfterRedirects === '/' || event.urlAfterRedirects === '/auth/sign-in')) {
          // Áp dụng cho người dùng đăng nhập bằng MXH khi khởi tạo app component sẽ check xem user đã chọn loại user chưa
          this._userService.getUserType().subscribe((res) => {
            if (!res) {
              this._router.navigate(['/auth/verify-user-type']);
            }
          });
          this._router.navigateByUrl('/main/wishlist');
        }
      }
    });
  }

  private _startHubConnection() {
    return new Promise((resolve, reject) => {

      this.hubLogoutConnection = new HubConnectionBuilder()
        .withUrl(`${environment.apis.default.url}/logout`).build();

      // kết nối tới hub
      this.hubLogoutConnection.start()
        .then(() => {
          console.log("connection logout established");
          return resolve(true);
        })
        .catch((err: any) => {
          console.log("error occured" + err);
          reject(err);
        });

      // Hàm nhận dữ liệu từ hub
      this.hubLogoutConnection.on("LogOutAsync", (res) => {
        console.log('LogOutAsync', res);
        const userId = this._configState.getOne('currentUser').id;
        if (userId === res) {
          this.logout();
        }
      });
    });
  }
}
