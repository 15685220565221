import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { ProductType } from '@proxy/gifts/product-type.enum';

export class CommonComponent {
  public static getDateForSave(date: Date) {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }

  public static getDateForEditFromString(date: string) {
    return date ? new Date(date) : null;
  }

  // truyền vào 1 formGroup để lấy trả vể chuôi = tên control có lỗi
  public static getControlErr(form: FormGroup) {
    let check = '';
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormArray) {
        const x = this.getControlArrErr(control);
        if (check === '') {
          check = x;
        }
      } else if (control.errors && check === '') {
        check = field;
      }
    });

    if (check !== '') {
      for (const control in form.controls) {
        if (form.controls.hasOwnProperty(control)) {
          form.get(control).markAsDirty({ onlySelf: true });
        }
      }
      CommonComponent.autoFocus(check);
      return check;
    } else {
      return '';
    }
  }

  public static getControlArrErr(form: FormArray) {
    let check = '';
    // BAT CO LOI TRUONG DAU TIEN
    form['controls'].forEach((item: FormGroup, yi: number) => {
      if (item.invalid) {
        for (const control_yi in item.controls) {
          if (item.get(control_yi).errors) {
            check = `${control_yi}_${yi}`;
            break;
          }
        }
      }
    });
    // DANH DAU TAT CA CAC INPUT LOI
    if (!_.isUndefined(check) && !_.isNull(check)) {
      form['controls'].forEach((element: FormGroup) => {
        for (const control_xi in element.controls) {
          // TRƯỜNG HỢP FORMCONTROL
          if (element.get(control_xi) instanceof FormControl) {
            element.get(control_xi).markAsTouched();
          }
        }
      });
    }
    return check;
  }

  // Truyền vào id để focus
  public static autoFocus(id: string) {
    console.log(123, $(`#${id}`));
    $(`#${id}`).focus();
  }

  public static checkUrlProductType(link): ProductType {
    if (link != null && link != '') {
      const trimmedValue = link.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
      if (
        trimmedValue.includes('amazon.co.jp') ||
        trimmedValue.includes('amazon.jp') ||
        trimmedValue.includes('amzn.asia')
      ) {
        return ProductType.Amazone;
      }
      if (trimmedValue.includes('malymoon')) {
        return ProductType.Malymoon;
      } else {
        return ProductType.EcSite;
      }
    } else {
      return ProductType.EcSite;
    }
  }
}
