import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetListGiftBoxViewOutputDto, UpdateCartInfoInputDto } from '@proxy/carts/dtos';
import { WishListFanViewService } from '@proxy/wish-list/wish-list-fan-view.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { GiftBoxTotalService } from 'src/app/main/_layout/services/gift-box-total.service';
import { AppComponentBase, WidthType } from 'src/app/shared/app-component-base';
import { GiftBoxService } from '../../services/gift-box.service';
import { ItemDetailDonatedComponent } from '../../shared/item-detail-donated/item-detail-donated.component';

@Component({
  selector: 'app-gipt-box',
  templateUrl: './gipt-box.component.html',
  styleUrls: ['./gipt-box.component.scss'],
})
export class GiptBoxComponent extends AppComponentBase implements OnInit {
  ref: DynamicDialogRef | undefined;
  dataGiptbox: GetListGiftBoxViewOutputDto;
  totalAmout: any;
  private dataKey = 'temporaryData'; // Key để lưu trữ dữ liệu
  visible = false;
  widthType = WidthType;

  isCheckout = false;
  isMobileScreen = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 767;
  }
  constructor(
    private router: Router,
    private giftBoxService: GiftBoxService,
    private dialogService: DialogService,
    private wishListFanViewService: WishListFanViewService,
    private giftBoxTotalService: GiftBoxTotalService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.giftBoxService.getData().subscribe(data => {
      this.visible = data;
      setTimeout(() => {
        this._getData();
      }, 0);
    });
  }

  deleteGiptbox(id: any, items: any, data: any) {
    const index = items?.findIndex(item => item.id === id);
    if (index !== -1) {
      // Xác định vị trí của phần tử cần xóa
      items[index].isDeleted = true;
      // Sử dụng setTimeout để xóa thực sự phần tử sau khi hoàn thành hiệu ứng fadeout
      setTimeout(() => {
        this.wishListFanViewService.deteleteCart(id).subscribe(() => {
          this.giftBoxService.setDataRemove(data, id);
          this._getData();
        });
      }, 1000);
    }
  }

  checkOut() {
    let changePrice = false;
    this.isCheckout = true;
    this.wishListFanViewService
      .getListGiftBoxWhenCheckoutBox(false)
      .pipe(finalize(() => (this.isCheckout = false)))
      .subscribe(res => {
        //lấy ra danh sách người tặng
        for (let index = 0; index < res.items.length; index++) {
          const items = res.items[index].giftInCart.filter(item => item.priceStatus !== null);
          if (items.length != 0) {
            const isAllPriceEqual = items.every(
              item => item.priceStatus.priceStatus === this.PriceStatusType.Equal
            );
            //nếu có 1 quà tặng có giá khác thì sắp xếp lại
            if (!isAllPriceEqual) {
              this.handleSortItemInCart();
              this.dataGiptbox = res;
              changePrice = true;
            }
          }
          // Là người tặng cuối cùng thì mới check  xem có quà tặng nào có giá khác không nếu không thì mới chuyển trang
          if(index === res.items.length - 1){
            if (!changePrice) {
              this.onHide();
              this.router.navigate(['/main/wishlist/fan-view/checkout/checkout'], {
                state: { dataGiptbox: this.dataGiptbox },
              });
              this.isCheckout = false
            }
          }
        }
      });
  }

  onHide() {
    this.giftBoxService.setData(false);
    const buttonElement = document.querySelector('.bg-light');
    if (buttonElement) {
      buttonElement.classList.remove('no-scroll');
    }
  }

  // GiftInCartViewDto
  confirmPrice(item, isFull?) {
    const input: UpdateCartInfoInputDto = {
      id: item.id,
      fund: 0,
      isDonateAll: true,
      message: item.message,
    };

    if (isFull) {
      input.fund = item.priceStatus.priceNew;
      input.isDonateAll = true;
    } else {
      input.fund = item.fund;
      input.isDonateAll = false;
    }

    this.wishListFanViewService.updateCartInfo(input).subscribe(() => {
      this.dataGiptbox.items.forEach(rs => {
        const giftChange = rs.giftInCart.find(rs => rs.id === item.id);
        if (giftChange !== undefined) {
          giftChange.isDonateAll = isFull;
          giftChange.fund = input.fund;
          giftChange.priceStatus = null;
        }
      });
      let total = 0;
      this.dataGiptbox.items.forEach(rs => {
        const totalFund = rs.giftInCart.reduce((accumulator, currentItem) => accumulator + currentItem.fund, 0);
        total = total + totalFund;
      });
      this.dataGiptbox.totalDonate = total;
    });
  }

  changePrice(item) {
    let contentStyle = {
      overflow: 'auto',
      overflowX: 'hidden',
      padding: '40px',
      borderRadius: '8px',
    };
    let width = '814px';
    let height = 'auto';

    if (this.isMobileScreen) {
      contentStyle = {
        overflow: 'auto',
        overflowX: 'hidden',
        padding: '24px',
        borderRadius: '8px',
      };
      width = '358px';
      height = '500px';
    }

    this.ref = this.dialogService.open(ItemDetailDonatedComponent, {
      data: { item: null, isgift: 0, itemInCart: item },
      contentStyle: contentStyle,
      styleClass: 'b-r-8',
      width: width,
      height: height,
      baseZIndex: 10000,
      maximizable: false,
      showHeader: false,
    });
    this.ref.onClose.subscribe(dataReturn => {
      if (dataReturn) {
        this._getData();
      }
    });
  }

  private handleSortItemInCart() {
    this.dataGiptbox.items.forEach(element => {
      element?.giftInCart?.sort(
        (a, b) => b.priceStatus?.priceStatus - a.priceStatus?.priceStatus
      );
    });
  }

  private _getData(): void {
    if (this.hasLoggedIn) {
      this.wishListFanViewService.getListGiftBox().subscribe(data => {
        this.dataGiptbox = data;
        this.giftBoxTotalService.setData(data?.totalGift);
      });
    }
  }
}
