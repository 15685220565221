<div class="home-wrap">
  <header class="header-wrap">
    <video *ngIf="!isMobileScreen" id="video-bg" autoplay loop muted playsinline>
      <source src="assets/videos/home.mp4" />
    </video>

    <ng-container *ngIf="isMobileScreen">
      <video id="video-bg-sp" autoplay loop muted playsinline>
        <source src="assets/videos/home-sp.mp4" />
      </video>
      <img id="img-bg-sp" src="assets/images/home/home-sp-img.png" alt="" />
    </ng-container>

    <div class="header-menu">
      <app-header-menu [screen]="'home'"></app-header-menu>
    </div>

    <div class="header-content d-flex flex-column">
      <div class="key-message text-left">
        <h3>{{ '::Home:Header:KeyMessage01' | abpLocalization }}</h3>
        <h3>{{ '::Home:Header:KeyMessage02' | abpLocalization }}</h3>
        <h3>{{ '::Home:Header:KeyMessage03' | abpLocalization }}</h3>
      </div>

      <p class="color-111 f-w-500 text-left m-b-32">
        {{ '::Home:Header:Text01' | abpLocalization }}
        <br />
        {{ '::Home:Header:Text02' | abpLocalization }}
      </p>

      <a routerLink="/auth/sign-up" class="btn-signup-large color-fff">
        {{ '::SignIn:SignUpLable' | abpLocalization }}
      </a>
    </div>
  </header>

  <main class="main-wrap">
    <!-- Icon social network -->
    <section class="section-platform text-center">
      <h4 class="color-000 f-s-32 f-w-700">
        {{ '::Home:Main:PlatformTitle' | abpLocalization }}
      </h4>
      <div class="section-platform-icons d-flex align-items-center justify-content-center">
        <img class="icon-social" src="assets/images/home/youtube.svg" alt="" />
        <img class="icon-social" src="assets/images/home/x.svg" alt="" />
        <img class="icon-social" src="assets/images/home/tiktok.svg" alt="" />
        <img class="icon-social" src="assets/images/home/twitch.svg" alt="" />
        <img class="icon-social" src="assets/images/icon/svg/instagram.svg" alt="" />
      </div>
    </section>

    <!-- Number 1 -->
    <section class="section-intro-one">
      <div class="section-intro">
        <div class="section-intro-left">
          <div class="section-intro-left-content">
            <h4 class="color-111 f-w-900">
              {{ '::Home:Main:IntroOneTitle01' | abpLocalization }}
              <br />
              {{ '::Home:Main:IntroOneTitle02' | abpLocalization }}
            </h4>
            <p class="color-718096 f-w-400">
              {{ '::Home:Main:IntroOneSubTitle01' | abpLocalization }}
              <br />
              {{ '::Home:Main:IntroOneSubTitle02' | abpLocalization }}
            </p>
          </div>
        </div>
        <div class="section-intro-right">
          <div class="section-intro-right-block-container">
            <div class="section-intro-right-block">
              <img
                class="section-img section-one-1"
                src="assets/images/home/section-one-1.png"
                alt=""
              />
              <img
                class="section-img section-one-2"
                src="assets/images/home/section-one-2.png "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section-box-wrap">
        <div class="section-box-pc">
          <div
            *ngFor="let item of boxItems[0]; let i = index"
            class="section-box-item"
            [ngClass]="{ 'm-r-60': i < boxItems[0].length - 1 }"
          >
            <div *ngIf="i === 0 || i === 1" class="section-box-item-img">
              <img
                *ngIf="i === 0"
                class="section-box-item-img-top"
                src="assets/images/home/box-one-1.png"
                alt=""
              />
              <img
                *ngIf="i === 1"
                class="section-box-item-img-top"
                src="assets/images/home/box-one-2.png"
                alt=""
              />
              <img class="section-box-item-img-mid" src="assets/images/home/polygon.svg" alt="" />
              <img
                class="section-box-item-img-bot"
                src="assets/images/home/box-one-share.png"
                alt=""
              />
            </div>

            <div *ngIf="i === 2" class="section-box-item-img">
              <img class="box-item-img-intro-1" [src]="item.img" alt="" />
            </div>
            <div class="section-box-item-title">
              {{ item.title1 | abpLocalization }}
              <br />
              {{ item.title2 | abpLocalization }}
            </div>
            <div class="section-box-item-text">
              {{ item.content1 | abpLocalization }}
              <ng-container *ngIf="item.content2 !== ''">
                <br />
                {{ item.content2 | abpLocalization }}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="section-box-sp">
          <app-carousel [carouselId]="01" [items]="boxItems[0]"></app-carousel>
        </div>
      </div>
    </section>

    <!-- Number 2 -->
    <section class="section-intro-two">
      <div class="section-intro">
        <div class="section-intro-left">
          <div class="section-intro-left-content">
            <h4 class="color-111 f-w-900">
              {{ '::Home:Main:IntroTwoTitle' | abpLocalization }}
            </h4>
            <p class="color-718096 f-w-400">
              {{ '::Home:Main:IntroTwoSubTitle01' | abpLocalization }}
              <br />
              {{ '::Home:Main:IntroTwoSubTitle02' | abpLocalization }}
              <br />
              {{ '::Home:Main:IntroTwoSubTitle03' | abpLocalization }}
            </p>
          </div>
        </div>
        <div class="section-intro-right">
          <div class="section-intro-right-block-container">
            <div class="section-intro-right-block">
              <img
                class="section-img section-two-1"
                src="assets/images/home/section-two-1.png"
                alt=""
              />
              <img
                class="section-img section-two-2"
                src="assets/images/home/section-two-2.png"
                alt=""
              />
              <img
                class="section-img section-two-3"
                src="assets/images/home/section-two-3.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section-box-wrap">
        <div class="section-box-pc">
          <div
            *ngFor="let item of boxItems[1]; let i = index"
            class="section-box-item"
            [ngClass]="{ 'm-r-60': i < boxItems[1].length - 1 }"
          >
            <div
              class="section-box-item-img"
              [ngClass]="{ 'p-x-18': i === 0, 'p-x-59': i === 1, 'p-x-49': i === 2 }"
            >
              <img [src]="item.img" alt="" />
            </div>
            <div class="section-box-item-title">
              {{ item.title1 | abpLocalization }}
              <br />
              {{ item.title2 | abpLocalization }}
            </div>
            <div class="section-box-item-text">
              {{ item.content1 | abpLocalization }}
              <ng-container *ngIf="item.content2 !== ''">
                <br />
                {{ item.content2 | abpLocalization }}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="section-box-sp">
          <app-carousel [carouselId]="02" [items]="boxItems[1]"></app-carousel>
        </div>
      </div>
    </section>

    <!-- Number 3 -->
    <section class="section-intro-three">
      <div class="section-intro">
        <div class="section-intro-left">
          <div class="section-intro-left-content">
            <h4 class="color-111 f-w-900">
              {{ '::Home:Main:IntroThreeTitle' | abpLocalization }}
            </h4>
            <p
              class="color-718096 f-w-400"
              [innerHTML]="
                (!isMobileScreen
                  ? '::Home:Main:IntroThreeSubTitle'
                  : '::Home:Main:IntroThreeSubTitleMb'
                ) | abpLocalization
              "
            ></p>
          </div>
        </div>
        <div class="section-intro-right">
          <div class="section-intro-right-block-container">
            <div class="section-intro-right-block">
              <img
                class="section-img section-three-1"
                src="assets/images/home/section-one-1.png"
                alt=""
              />
              <img
                class="section-img section-three-2"
                src="assets/images/home/section-three-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section-box-wrap">
        <div class="section-box-pc">
          <div
            *ngFor="let item of boxItems[2]; let i = index"
            class="section-box-item"
            [ngClass]="{ 'm-r-60': i < boxItems[2].length - 1 }"
          >
            <div class="section-box-item-img">
              <img [src]="item?.img" alt="" />
              <img *ngIf="i === 0" class="icon-center" [src]="imgLogo" alt="" />
            </div>
            <div class="section-box-item-title">
              {{ item?.title1 | abpLocalization }}
              <br />
              {{ item?.title2 | abpLocalization }}
            </div>
            <div class="section-box-item-text">
              {{ item?.content1 | abpLocalization }}
              <ng-container *ngIf="item?.content2 !== ''">
                <br />
                {{ item?.content2 | abpLocalization }}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="section-box-sp">
          <app-carousel [carouselId]="03" [items]="boxItems[2]"></app-carousel>
        </div>
      </div>
    </section>

    <!-- Section Security -->
    <section class="section-security">
      <div class="section-security-content"></div>
      <div class="section-security-content">
        <h4 class="color-fff f-w-900 text-center">
          {{ '::Home:Main:SecurityTitle' | abpLocalization }}
        </h4>
        <p>{{ '::Home:Main:SecurityText01' | abpLocalization }}</p>
        <p>{{ '::Home:Main:SecurityText02' | abpLocalization }}</p>
        <p>{{ '::Home:Main:SecurityText03' | abpLocalization }}</p>
        <img class="icon-under" src="assets/images/home/icon-section-security.png" alt="" />
      </div>
    </section>

    <!-- Other Brand Icons -->
    <section class="section-ecommerce">
      <h4 class="f-w-900 color-111 text-center">
        {{ '::Home:Main:EcommerceTitle' | abpLocalization }}
      </h4>
      <div [ngClass]="isMobileScreen ? 'ecommerce-icons-mobile' : 'ecommerce-icons-desktop'">
        <ng-container *ngFor="let items of iconsBrand; let iIconBrand = index">
          <div
            class="ecommerce-icons d-flex align-items-center"
            [ngClass]="{
              'section-ecommerce-icon-top': iIconBrand === 0,
              'section-ecommerce-icon-mid': iIconBrand === 1,
              'section-ecommerce-icon-bot': iIconBrand === 2
            }"
          >
            <div class="ecommerce-icons-l1 d-flex align-items-center">
              <ng-container *ngFor="let item of items.listIcon">
                <img class="icon-social" [src]="item.icon" alt="" />
              </ng-container>
            </div>
            <div class="ecommerce-icons-l2 d-flex align-items-center">
              <ng-container *ngFor="let item of items.listIcon">
                <img class="icon-social" [src]="item.icon" alt="" />
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </section>

    <!-- End Section -->
    <section class="section-community d-flex flex-column align-items-center justify-content-center">
      <video id="video-community" autoplay loop muted playsinline>
        <source src="assets/videos/section_community.mp4" />
      </video>
      <img
        *ngIf="isMobileScreen"
        id="img-community-sp"
        src="assets/images/home/community-sp-img.png"
        alt=""
      />

      <div class="section-community-content">
        <h4 class="color-111 f-w-900">
          {{ '::Home:Main:BuildCommunityTitle' | abpLocalization }}
        </h4>
        <p class="color-111 f-w-500">
          {{ '::Home:Main:BuildCommunityText' | abpLocalization }}
        </p>
        <a routerLink="/auth/sign-up" class="btn-signup btn-signup-large color-fff">
          {{ '::SignIn:SignUpLable' | abpLocalization }}
        </a>
      </div>
    </section>
  </main>

  <app-footer [screen]="'home'"></app-footer>
</div>
<!-- [ngClass]="{ 'd-none': isLoad }" -->
<!-- <div *ngIf="isLoad" class="home-loading-wrap">
  <div class="loader">
    <div class="loader-inner">
      <div class="loader-line-wrap">
        <div class="loader-line"></div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line"></div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line"></div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line"></div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line"></div>
      </div>
    </div>
  </div>
</div> -->
