import { Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUserService } from '@proxy/users';
import { ActiveLogoHeader } from 'src/app/main/_layout/services/active-logo-header';
import { GiftBoxTotalService } from 'src/app/main/_layout/services/gift-box-total.service';
import { GiftBoxService } from 'src/app/main/wishlist/services/gift-box.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent extends AppComponentBase implements OnInit {
  @Input() screen = 'page';

  giftToltal = 0;
  isMobileScreen = false;
  currentUrl = '';

  menuItems = [
    {
      id: 0,
      url: '/main/wishlist/creator-view',
      text: '::Wishlist:MenuHeader:Wishlist',
      icon: 'assets/images/icon/svg/gift-box-icon.svg',
    },
    {
      id: 1,
      url: '/main/notification',
      text: '::Wishlist:MenuHeader:Feed',
      icon: 'assets/images/icon/svg/message.svg',
    },
    {
      id: 2,
      url: '/main/creator-search',
      text: '::Wishlist:MenuHeader:Search',
      icon: 'assets/images/icon/svg/filter.svg',
    },
  ];

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 575;
  }
  constructor(
    injector: Injector,
    private router: Router,
    private giftBoxService: GiftBoxService,
    private giftBoxTotalService: GiftBoxTotalService,
    private activeLogoHeader: ActiveLogoHeader,
    private _appUserService: AppUserService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.currentUrl = this.router.url.split(/[?#]/)[0];
    this.giftBoxTotalService.getData().subscribe(data => {
      this.giftToltal = data >= 100 ? '99+' : data;
    });

    this.activeLogoHeader.getData().subscribe(s => {
      if (s) {
        this.activeLogo();
      }
    });

    this.activeLogoHeader.getUrlCurrent().subscribe(s => {
      if (s) {
        this.changeMenu(s);
      }
    });
  }

  openGitpBox() {
    this.giftBoxService.setData(true);
    const buttonElement = document.querySelector('.bg-light');
    if (buttonElement) {
      buttonElement.classList.add('no-scroll');
    }
  }

  isMenuItemActive(url: string) {
    if (this.currentUrl === url) {
      return true;
    }
    return false;
  }

  activeLogo() {
    this.changeMenu('/main/wishlist/creator-view');
  }

  changeMenu(url: string) {
    this.currentUrl = url;
  }
}
