import { CoreModule } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PrimengSharedModule } from './primeng-shared.module';

import { LabelValidationComponent } from './dft/component/lable-validate.component';
import { ValidationComponent } from './dft/component/validation-messages.component';
import { JapanesePhoneNumberDirective } from './dft/directives/japanese-phone-number.service';
import { TienTePipe } from './dft/pipe/tiente.pipe';
import { TimeAgoPipe } from './dft/pipe/time-ago.pipe';
import { UserFollowFormatPipe } from './dft/pipe/user-follow.pipe';
import { PopupConfirmComponent } from './dft/component/popup-confirm/popup-confirm.component';
import { FooterComponent } from '../page/footer/footer.component';
import { GiptBoxComponent } from '../main/wishlist/fan-view/gipt-box/gipt-box.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { HeaderMenuComponent } from '../page/header-menu/header-menu.component';
import { NumbersOnlyDirective } from './dft/directives/only-number.directive';

@NgModule({
  declarations: [
    ValidationComponent,
    LabelValidationComponent,
    TienTePipe,
    TimeAgoPipe,
    UserFollowFormatPipe,

    NumbersOnlyDirective,
    JapanesePhoneNumberDirective,
    PopupConfirmComponent,
    FooterComponent,
    HeaderMenuComponent,
    GiptBoxComponent,
  ],
  imports: [
    CoreModule,
    NgbDropdownModule,
    PrimengSharedModule,
    NgxCurrencyDirective
  ],
  exports: [
    CoreModule,
    NgbDropdownModule,
    PrimengSharedModule,
    ValidationComponent,
    LabelValidationComponent,
    FooterComponent,
    HeaderMenuComponent,
    GiptBoxComponent,
    TienTePipe,
    TimeAgoPipe,
    UserFollowFormatPipe,

    JapanesePhoneNumberDirective,
    NgxCurrencyDirective,
    NumbersOnlyDirective,
  ],
  providers: []
})
export class SharedModule { }
