import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { FooterChangeImgServiceService } from '../main/_layout/services/footer-change-img.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent extends AppComponentBase implements OnInit {
  isMobileScreen = false;
  variations = [];
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 767;
  }
  constructor(
    injector: Injector,
    private footerChangeImgServiceService: FooterChangeImgServiceService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.handleScrollToTop('instant');
    this.footerChangeImgServiceService.setData(this.screenType.mixDefaultCross);
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.footerChangeImgServiceService.setData(this.screenType.cross);
  }
}
