<div *ngIf="isShow" class="row">
  <!-- Start: Mobile item -->
  <div class="col-12 mobile-view">
    <div class="mob-btn-close-dialog" (click)="closeDialog()">
      <img src="assets/images/icon/svg/close.svg" alt="circle times" />
    </div>

    <div class="d-flex align-items-center m-b-16">
      <img
        class="m-r-8 icon-title"
        src="assets/images/icon/svg/gift-box-icon.svg"
        alt="gift icon"
      />
      <span class="f-s-24 f-w-500 color-111">
        {{
          (this.dialogService?.data?.item?.id
            ? '::OrderManagement:EditGiftDetail'
            : '::OrderManagement:AddGiftDetail'
          ) | abpLocalization
        }}
      </span>
    </div>
  </div>
  <!-- End: Mobile item -->

  <div class="col-12 col-md-5 desktop-view desktop-gallery">
    <ng-container *ngIf="!loadingData; else showDesktopGallery">
      <p-galleria
        class="custom-thumbnail-pc"
        (activeIndexChange)="activeIndexChange('custom-thumbnail-pc')"
        [(value)]="images"
        [autoPlay]="false"
        [numVisible]="3"
      >
        <ng-template pTemplate="item" let-item>
          <div class="loading-container">
            <img [src]="item.itemSrc" *ngIf="!loadingData" alt="" />
          </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="row row-nogutter justify-content-center">
            <img *ngIf="!loadingData" [src]="item.thumbnailSrc" alt="" />
          </div>
        </ng-template>
      </p-galleria>
    </ng-container>

    <ng-template #showDesktopGallery>
      <div class="loading-container">
        <div class="img-loading">
          <div class="images-loadding-center">
            <img src="/assets/images/icon/svg/loadding.svg" alt="" />
            <br />
            <label for="">Loading...</label>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="col-12 col-md-7" [ngClass]="{ 'p-l-21': !isMobileScreen }">
    <form [formGroup]="formData" autocomplete="off">
      <dft-label-validation
        class="f-s-12 f-w-500 color-111"
        [title]="'::Wishlist:Creator:Detail:ProductName' | abpLocalization"
      >
      </dft-label-validation>
      <textarea
        maxlength="4000"
        class="w-100 custom-textarea"
        rows="2"
        cols="12"
        pInputTextarea
        formControlName="Title"
      ></textarea>
      <dft-validation [control]="formData.get('Title')"></dft-validation>
      <div class="text-end f-s-12 f-w-400 color-111 m-b-4"><span>amazon.co.jp</span></div>

      <!-- Start: Mobile item -->
      <div class="m-t-10 m-b-16 mobile-view mobile-gallery-right">
        <ng-container *ngIf="!loadingData; else showMobileGallery">
          <p-galleria
            [(value)]="images"
            [containerStyle]="{ 'max-width': '310px' }"
            [numVisible]="4"
            [showThumbnailNavigators]="false"
            thumbnailsPosition="right"
            verticalThumbnailViewPortHeight="245px"
          >
            <ng-template pTemplate="item" let-item>
              <div class="preview-img-custom">
                <img [src]="item.itemSrc" alt="" />
              </div>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
              <div class="thumbnail-img-custom">
                <img [src]="item.thumbnailSrc" alt="" />
              </div>
            </ng-template>
          </p-galleria>
        </ng-container>
        <ng-template #showMobileGallery>
          <div class="loading-container-mobile">
            <div class="img-loading-mobile">
              <div class="images-loadding-center-mobile">
                <img src="/assets/images/icon/svg/loadding.svg" alt="" />
                <br />
                <label for="">Loading...</label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End: Mobile item -->

      <div class="row">
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-11 p-r-8': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:ProductUnitPrice' | abpLocalization"
          >
          </dft-label-validation>
          <input
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            maxlength="12"
            currencyMask
            formControlName="ProductUnitPrice"
            class="p-inputtext"
          />
        </div>
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-8 p-r-11': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:Quantity' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <p-inputNumber
              (change)="onChangeQuantity($event)"
              min="1"
              max="10"
              class="w-100 custom-quantity"
              [style]="{ width: '100%' }"
              formControlName="Quantity"
              locale="en-US"
            >
            </p-inputNumber>

            <dft-validation [control]="formData.get('Quantity')"></dft-validation>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          formArrayName="opTions"
          class="col-12 col-sm-6 m-b-8"
          *ngFor="let ii of itemListOption.controls; let i = index"
        >
          <div [formGroup]="ii" class="row">
            <dft-label-validation
              class="f-s-12 f-w-500 color-111"
              [title]="getcontrols(ii)"
              [control]="ii.get('name')"
            >
            </dft-label-validation>
            <p-dropdown
              appendTo="body"
              (onChange)="filterOption($event, getcontrols(ii))"
              [ngClass]="{ 'disabled-dropdown': isDisAbled, 'p-l-8 p-r-8': !isMobileScreen }"
              class="custom-dropdown"
              type="text"
              [formControlName]="getcontrols(ii)"
              [options]="ii.get('arrValue').value"
              [placeholder]="'::Wishlist:Creator:Detail:Choice' | abpLocalization"
              optionLabel="displayName"
              optionValue="id"
            >
            </p-dropdown>
            <div *ngIf="ii.get(getcontrols(ii))?.invalid">
              <span style="font-size: 13px !important; color: #DC3545;">
                  {{ '::Validation:FieldIsRequired' | abpLocalization }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-11 p-r-8': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:Postage' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <input
              (change)="onChangePostage($event)"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="12"
              currencyMask
              formControlName="Postage"
              class="p-inputtext"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-8 p-r-11': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:TotalProductAmount' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <input
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              currencyMask
              formControlName="TotalProductAmount"
              class="p-inputtext"
            />
          </div>
        </div>
        <div class="col-12">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111 m-t-8"
            [title]="'::CreatorDonate:Profile:AddWishListMannually:Remarks' | abpLocalization"
          >
          </dft-label-validation>
          <textarea
            placeholder="{{
              '::CreatorDonate:Profile:AddWishListMannually:RemarksPlaceholder' | abpLocalization
            }}"
            maxlength="4000"
            class="w-100 custom-textarea"
            rows="2"
            cols="12"
            pInputTextarea
            formControlName="Remarks"
          ></textarea>
        </div>
        <div class="col-12">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:Message' | abpLocalization"
          >
          </dft-label-validation>
          <div class="textarea-item-detail-custom">
            <textarea pInputTextarea formControlName="message" maxlength="100"></textarea>
          </div>
        </div>
      </div>
      <div class="row m-t-16 m-b-24">
        <div class="text-note col-12 col-sm-12">
          <span
            >・商品総額には、Gipt手数料が含まれています。決済時に別途、決済手数料と消費税がかかります。
          </span>
          <br />
          <span> ・商品登録後、課金がされたアイテムの編集・削除・キャンセルは行えません。 </span>
        </div>
      </div>
      <p-button
      *ngIf="giftStatusDetail === giftStatus.Created || (!isView )"
        [loading]="loading"
        [disabled]="loadingData || loading"
        (click)="saveData()"
        styleClass="p-button-rounded  w-100 button-content m-b-8"
      >
        <span class="me-2 f-s-13 f-w-500">
          {{
            !isView
              ? ('::Wishlist:Creator:Detail:Addition' | abpLocalization)
              : ('::OrderManagement:ButtonSave' | abpLocalization)
          }}
        </span>
      </p-button>
      <p-button (click)="closeDialog()" styleClass="custom-btn-cancel w-100">
        <span class="me-2 f-s-14 f-w-500 color-111">{{
          '::Wishlist:Creator:Detail:Cancel' | abpLocalization
        }}</span>
      </p-button>
    </form>
  </div>
</div>
