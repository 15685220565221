import { transition } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwipeService } from 'src/app/main/_layout/services/SwipeService.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [

  ],
})
export class CarouselComponent implements OnInit {
  @ViewChild('carouselSlide') carouselSlide: any;
  @Input() carouselId: any;
  @Input() items: any;
  @Input() infinity: any = true;

  // Thêm mảng mẫu để chuyển đổi mảng items thành mảng tham chiếu
  itemSample: any;
  // Thêm mảng tham chiếu để hiển thị indicators
  itemArray: any[] = [];
  // Thêm mảng để xác định mảng được swipe
  itemSwipe: any[] = [];

  // Id đang được tác động
  currentSwipeId: any;

  // đang trong quá trình swipe
  isSwipe = false;

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  currentItem = 0;
  currentIndicator = 0;
  startItem = 0;
  endItem = 0;

  constructor(
    private swipeService: SwipeService,
  ) {

  }

  ngOnInit(): void {
    this.itemArray = [...this.items];
    this.itemSample = [...this.items];

    if (this.infinity) {
      this.startItem = 1;
      this.currentItem = 1;
      // Lấy item đầu tiên của mảng items thêm vào cuối cùng để tạo hiệu ứng lặp vô hạn
      this.itemSample.push(this.items[0]);
      // Lấy item cuối cùng của mảng items thêm vào đầu tiên để tạo hiệu ứng lặp vô hạn
      this.itemSample.unshift(this.items[this.items.length - 1]);
    } else {
      this.startItem = 0;
      this.currentItem = 0;
    }

    this.swipeService.currentSwipeId.subscribe(id => {
      this.currentSwipeId = id;
    });

    this.swipeService.currentMessage.subscribe(message => {
      if (this.carouselId === this.currentSwipeId) {
        if (message) {
          const carouselSlide = document.getElementById('carousel-' + this.currentSwipeId);
          if (message === 'next') {
            this.currentItem += 1;
            this.currentIndicator += 1;

            if (this.currentIndicator > this.itemArray.length - 1) {
              this.currentIndicator = 0;
            }

            if (this.infinity && this.currentItem > this.itemSample.length - 2 || !this.infinity && this.currentItem > this.itemSample.length - 1) {
              setTimeout(() => {
                if (carouselSlide) {
                  this.currentItem = this.startItem;
                  carouselSlide.style.transition = '';
                  carouselSlide.style.transform = `translate3d(-${this.currentItem * 100}%, 0%, 0)`;
                }
              }, 500);
            }

            // Kéo đến slide tiếp theo với animation slide
            if (carouselSlide) {
              carouselSlide.style.transform = `translate3d(-${this.currentItem * 100}%, 0%, 0)`;
              carouselSlide.style.transition = 'transform .5s ease 0s';
            }
          }
          else {
            this.currentItem -= 1;
            this.currentIndicator -= 1;

            if (this.currentIndicator < 0) {
              this.currentIndicator = this.itemArray.length - 1;
            }

            if (this.currentItem < this.startItem) {
              if (this.infinity) {
                setTimeout(() => {
                  if (carouselSlide) {
                    this.currentItem = this.itemSample.length - 2;
                    carouselSlide.style.transition = '';
                    carouselSlide.style.transform = `translate3d(-${this.currentItem * 100}%, 0%, 0)`;
                  }
                }, 500);
              } else {
                this.currentItem = this.itemSample.length - 1;
              }
            }

            // Kéo trở lại slide trước với animation slide
            if (carouselSlide) {
              carouselSlide.style.transform = `translate3d(-${this.currentItem * 100}%, 0%, 0)`;
              carouselSlide.style.transition = 'transform .5s ease 0s';
            }
          }

          setTimeout(() => this.isSwipe = false, 500);
        }
      }
    });

    setTimeout(() => {
      // Add vị trí khi khởi tạo của swipe
      const carouselSlide = document.getElementById('carousel-' + this.carouselId);
      if (carouselSlide) {
        if (this.infinity) {
          carouselSlide.style.transform = `translate3d(-100%, 0%, 0)`;
        } else {
          carouselSlide.style.transform = `translate3d(0%, 0%, 0)`;
        }
      }
    }, 0);
  }

  swipe(e, when, id) {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = new Date().getTime();
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = new Date().getTime() - this.swipeTime;

      if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (!this.isSwipe) {
          this.isSwipe = true;
          if (swipe === 'next') {
            this.swipeService.setSwipeId(id);
            this.swipeService.swipeNext();
          } else {
            this.swipeService.setSwipeId(id);
            this.swipeService.swipePrevious();
          }
        }
      }
    }

  }
}
