<div>
    <img src="assets/images/icon/svg/warning.svg" alt="">
    <br>
    <div class="text-title">
        <label  for="">{{'::Wishlist:Creator:Delete:AcceptTitle' | abpLocalization}}</label>
    </div>
    <p-button  (click)="closeDialog(true)"
        styleClass="custom-btn-delete p-button-outlined w-100 m-b-8">
        <span class="me-2 f-s-14 f-w-500">{{'::Wishlist:CheckOut:Delete' | abpLocalization}}</span>
    </p-button>
    <p-button (click)="closeDialog(false)" styleClass="custom-btn-cancel w-100">
        <span class="me-2 f-s-14 f-w-500 color-111">{{'::Wishlist:Creator:Detail:Cancel' |
            abpLocalization}}</span>
    </p-button>
</div>
