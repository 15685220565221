import { AuthService, ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Injector } from '@angular/core';
import { ErrorResultDto } from '@proxy/global/dtos';
import { MessageService } from 'primeng/api';
import { AppUserService } from '@proxy/users';
import { environment } from 'src/environments/environment';
import { PriceStatusType } from '@proxy/wish-list/price-status-type.enum';
import { Meta } from '@angular/platform-browser';

export enum WidthType {
  W30 = 0,
  W40,
  W80,
  W100,
  W120,
  W200,
  W250,
  W256,
  W300,
  W512,
  W768,
  W820,
  W1024,
  W1280,
  W1920,
}
export abstract class AppComponentBase {
  localizationService: LocalizationService;
  messageService: MessageService;
  authService: AuthService;
  userService: AppUserService;
  serverUrl = '';
  fileserverUrl = '';
  openIdUrl = '';
  baseUrl = '';
  bucketname = '';
  screenType = {
    cross: 0, // Cho các màn có footer chéo (cả mobile và desktop)
    checkout: 1,
    mixDefaultCross: 2, // Màn có footer chéo ở mobile và footer thẳng ở desktop
    mixCrossDefault: 3, // Màn có footer thẳng ở mobile và footer chéo ở desktop
    default: 4, // Cho các màn có footer thẳng (cả mobile và desktop)
  };

  avatarImageDefault = 'assets/images/icon/png/avatarDefault.png';
  coverImageDefault = 'assets/images/icon/png/coverDefault.png';
  configStateService: ConfigStateService;

  publicKey = '';
  constructor(injector: Injector) {
    this.localizationService = injector.get(LocalizationService);
    this.messageService = injector.get(MessageService);
    this.authService = injector.get(AuthService);
    this.userService = injector.get(AppUserService);
    this.serverUrl = environment.apis.default.url;
    this.fileserverUrl = environment.fileserverUrl;
    this.openIdUrl = environment.oAuthConfig.issuer;
    this.baseUrl = environment.application.baseUrl;
    this.configStateService = injector.get(ConfigStateService);
    this.bucketname = environment.bucketname;
    this.publicKey = environment.komoju.publicKey;
  }

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  get PriceStatusType() {
    return PriceStatusType;
  }

  getCurrentUser() {
    return this.configStateService.getOne('currentUser');
  }

  showCreateSuccessMessage() {
    this.showSuccessMessage(this.L('::Message:CreateMessage'));
  }

  showUpdateSuccessMessage() {
    this.showSuccessMessage(this.L('::Message:UpdateMessage'));
  }

  showDeleteSuccessMessage() {
    this.showSuccessMessage(this.L('::Message:DeleteMessage'));
  }

  showSuccessMessage(message: string) {
    this.messageService.clear('tms');
    this.messageService.add({
      key: 'tms',
      severity: 'success',
      summary: this.localizationService.instant('::Message:Success'),
      detail: message,
    });
  }

  showInfoMessage(message: string) {
    this.messageService.clear('tms');
    this.messageService.add({
      key: 'tms',
      severity: 'info',
      summary: this.localizationService.instant('::Message:Info'),
      detail: message,
    });
  }

  showWarningMessage(message: string) {
    this.messageService.clear('tms');
    this.messageService.add({
      key: 'tms',
      severity: 'warn',
      summary: this.localizationService.instant('::Message:Warn'),
      detail: message,
    });
  }

  showErrorMessage(message: string) {
    this.messageService.clear('tms');
    this.messageService.add({
      key: 'tms',
      severity: 'error',
      summary: this.localizationService.instant('::Message:Error'),
      detail: message,
    });
  }

  getErrorResult(error: any): ErrorResultDto {
    return {
      code: '',
      message: '',
    };
  }

  L(key: string) {
    return this.localizationService.instant(key);
  }

  logout() {
    this.userService.logOut().subscribe(() => {
      this.authService.logout().subscribe();
    });
  }

  handlerError(err) {
    if (err && err.error.error.message.includes('Your request is not valid!')) {
      this.showErrorMessage(this.L('::CreatorDonate:AddWishList:SomeItemsHaveNotBeenSet'));
    } else {
      this.showErrorMessage(err.error.error.message);
    }
  }

  handlerErrorUploadfile(err) {
    this.showErrorMessage(err.error.message);
  }

  getImageFromUrl(url: string, type: WidthType) {
    if (url && url.startsWith('Upload')) {
      return this.getUrlImgFromServer(url, type);
    } else {
      return url;
    }
  }

  getAudioFromUrl(url: string) {
    if (url && url.startsWith('Upload')) {
      // convert url to base64
      let base64 = this.convertUrlToBase64(this.bucketname + url);
      return "https://img.gi-pt.com/audio/raw/" + base64;
      // return `${this.fileserverUrl}/${url}`;
    } else {
      return url;
    }
  }
  getCoverDefault() {
    return 'assets/images/icon/svg/coverdefault.svg';
  }
  getAvatarDefault() {
    return 'assets/images/icon/svg/avatardefault.svg';
  }
  getImgManuallyErrorDefault() {
    return 'assets/images/icon/svg/item-manually-defaut.svg';
  }
  getLinkTwitch(twitch: string) {
    return 'https://www.twitch.tv/' + twitch;
  }
  getLinkInstagram(instagram: string) {
    return 'https://www.instagram.com/' + instagram;
  }
  getLinkYoutube(youtube: string) {
    return 'https://www.youtube.com/@' + youtube;
  }
  getLinkTiktok(tiktok: string) {
    return 'https://www.tiktok.com/@' + tiktok;
  }
  getLinkTwitter(twitter: string) {
    return 'https://twitter.com/' + twitter;
  }

  handleScrollToTop(behavior: any) {
    setTimeout(() => window.scrollTo({ top: 0, behavior: behavior }));
  }

  getImageOGP(imgUrl: any, defaultImage: string) {
    if (imgUrl.startsWith('http') || imgUrl.startsWith('data:image/')) {
      return imgUrl;
    }
    return `${this.baseUrl}/${defaultImage}`;
  }

  handleAddMetaTag(meta: Meta, params: any, isCopy?: boolean): void {
    let cover = this.getImageOGP(params.coverImage, this.coverImageDefault);
    let avatar = this.getImageOGP(params.avatarImage, this.avatarImageDefault);

    // Random 1 số để tránh trường hợp meta tag không được cập nhật
    const random = Math.floor(Math.random() * 1000);
    const url = params.url + '?random=' + random;
    cover = cover + '?' + random;
    avatar = avatar + '?' + random;

    if (!params.description) {
      meta.updateTag({ name: 'description', content: params.description });
    }

    // Add meta tag
    meta.updateTag({ property: 'og:url', content: url });
    meta.updateTag({ property: 'og:type', content: 'website' });
    meta.updateTag({ property: 'og:locale', content: 'ja_JP' });
    meta.updateTag({ property: 'og:title', content: params.displayName });
    meta.updateTag({ property: 'og:description', content: params.description });
    meta.updateTag({ property: 'og:image', content: avatar });

    meta.updateTag({ name: 'twitter:card', content: 'summary' });
    meta.updateTag({ name: 'twitter:url', content: url });
    meta.updateTag({ name: 'twitter:creator', content: '@' + params.accountName });
    meta.updateTag({ name: 'twitter:title', content: params.displayName });
    meta.updateTag({ name: 'twitter:description', content: params.description });
    meta.updateTag({ name: 'twitter:image', content: cover });

    if (isCopy) {
      const textArea = document.createElement('textarea');
      textArea.value = params.url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.showSuccessMessage(this.L('::CreatorDonate:WishList:CopySuccess'));
    }
  }

  handleShortDescription() {
    const resizeObserver = new ResizeObserver(entries => this.isTextTruncated(entries[0].target));
    const shortDescriptionOb = document.querySelector('.short-description');
    if (shortDescriptionOb) {
      resizeObserver.observe(shortDescriptionOb);
    }
  }

  handleTruncateUserInfo(isMobile: boolean): void {
    const place = document.querySelector('.user-info-place');
    const birthDay = document.querySelector('.user-info-birth-day');
    const url = document.querySelector('.user-info-url');
    const userInfo = document.querySelector('.user-info');

    let resizeObserver = new ResizeObserver(() =>
      this.isTextTruncateUserInfo(place, birthDay, url, userInfo, isMobile)
    );

    if (userInfo) {
      resizeObserver.observe(userInfo);
    }
  }

  isTextTruncated(ele: any) {
    if (ele) {
      const truncated = ele.scrollHeight >= 60;
      ele.classList.toggle('truncated', truncated);
    }
  }

  isTextTruncateUserInfo(place: any, birthDay: any, url: any, userInfo: any, isMobile: boolean) {
    const sumWidth = userInfo.offsetWidth - 21;
    if (place && birthDay && url && userInfo) {
      // Màn mobile
      let percentByScreen = 0.65;
      if (isMobile) {
        percentByScreen = 0.5;
      }
      if (place.offsetWidth + birthDay.offsetWidth + 28 <= sumWidth * percentByScreen) {
        userInfo.classList.toggle('flex-wrap', false);
        this.setTruncatedText(url, sumWidth - place.offsetWidth - 14 - birthDay.offsetWidth - 14);
      } else {
        userInfo.classList.toggle('flex-wrap', true);
        this.setTruncatedText(place, sumWidth - birthDay.offsetWidth - 30);
        this.setTruncatedText(url, sumWidth);
      }
    } else if (birthDay && url && userInfo) {
      userInfo.classList.toggle('flex-wrap', false);
      this.setTruncatedText(url, sumWidth - birthDay.offsetWidth - 14);
    } else if (place && url && userInfo) {
      userInfo.classList.toggle('flex-wrap', false);
      this.setTruncatedText(url, sumWidth - place.offsetWidth - 14);
    } else if (place && birthDay && userInfo) {
      userInfo.classList.toggle('flex-wrap', false);
      this.setTruncatedText(place, sumWidth - birthDay.offsetWidth - 30);
    } else if (place && userInfo) {
      userInfo.classList.toggle('flex-wrap', false);
      this.setTruncatedText(place, sumWidth);
    } else if (url && userInfo) {
      userInfo.classList.toggle('flex-wrap', false);
      this.setTruncatedText(url, sumWidth);
    }
  }

  private setTruncatedText(ele: any, sumWidth: number) {
    ele.classList.toggle('truncated-text', true);
    ele.style.maxWidth = sumWidth + 'px';
  }

  handleAvatarError(event) {
    event.target.src = this.getAvatarDefault();
  }
  handleCoverError(event) {
    event.target.src = this.getCoverDefault();
  }
  handleAddItemManuallyError(event) {
    event.target.src = this.getImgManuallyErrorDefault();
  }
  transformPrice(value): string {
    if (value == null) {
      return 0 + '';
    }
    return !isNaN(value)
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : value.toString();
  }

  // Hàm chuyển đổi string sang dạng số của bàn phím tiếng Nhật
  convertPriceStringToNum(value: any) {
    if (value) {
      // Xóa text ngoại trừ '０-９'
      const numericValue = value.replace(/[^０-９0-9]/g, '');

      return Number(
        numericValue.replace(/[０-９]/g, s => {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
      );
    }
  }

  // Hàm chuyển đổi từ fullsize sang halfsize
  convertToHalfSize(value: string) {
    if (value) {
      // Xóa hết ký tự khác ０-９, 0-9
      const numericValue = value.replace(/[^０-９0-9]/g, '');
      // Convert số ０-９ sang 0-9
      return numericValue.replace(/[０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });
    }
  }

  getCurrencyMaskValue(value: string) {
    if (value) {
      // Loại bỏ dấu ¥, dấu , và chuyển đổi thành số
      const numericValue = value.replace(/,/g, '');
      return Number(numericValue.replace('¥', ''));
    }
  }

  checkUrlPattern(url) {
    // Kiểm tra xem chuỗi có bắt đầu bằng "http://" hoặc "https://" hay không
    const hasProtocol = /^(https?:\/\/)/i.test(url);

    // Nếu có protocol, thì kiểm tra với biểu thức chính quy
    if (hasProtocol) {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%_.~+]*)*' + // path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
      );
      return urlPattern.test(url);
    } else {
      return false;
    }
  }

  handleGetNameFromFileUpload(url: string) {
    if (url && url.startsWith('Upload')) {
      const arr = url.split('/');
      return arr[arr.length - 1];
    }
    return url;
  }

  convertUrlToBase64(path: string) {
    return btoa(path);
  }

  resizeVideo(url) {
    let base64 = this.convertUrlToBase64(this.bucketname + url);
    return "https://img.gi-pt.com/video/raw/" + base64;
  }

  getUrlImgFromServer(url, type: WidthType) {
    if (url?.startsWith('Upload')) {
      let base64 = this.convertUrlToBase64(this.bucketname + url)
      switch (type) {
        case WidthType.W30:
          return "https://img.gi-pt.com/image/w30/" + base64;
        case WidthType.W40:
          return "https://img.gi-pt.com/image/w40/" + base64;
        case WidthType.W80:
          return "https://img.gi-pt.com/image/w80/" + base64;
        case WidthType.W100:
          return "https://img.gi-pt.com/image/w100/" + base64;
        case WidthType.W120:
          return "https://img.gi-pt.com/image/w120/" + base64;
        case WidthType.W200:
          return "https://img.gi-pt.com/image/w200/" + base64;
        case WidthType.W250:
          return "https://img.gi-pt.com/image/w250/" + base64;
        case WidthType.W256:
          return "https://img.gi-pt.com/image/w256/" + base64;
        case WidthType.W300:
          return "https://img.gi-pt.com/image/w300/" + base64;
        case WidthType.W512:
          return "https://img.gi-pt.com/image/w512/" + base64;
        case WidthType.W768:
          return "https://img.gi-pt.com/image/w768/" + base64;
        case WidthType.W820:
          return "https://img.gi-pt.com/image/w820/" + base64;
        case WidthType.W1024:
          return "https://img.gi-pt.com/image/w1024/" + base64;
        case WidthType.W1280:
          return "https://img.gi-pt.com/image/w1280/" + base64;
        case WidthType.W1920:
          return "https://img.gi-pt.com/image/w1920/" + base64;
      }
    } else {
      return url;
    }
  }
}
