import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule } from '@abp/ng.core';
import { registerLocale, storeLocaleData } from '@abp/ng.core/locale';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginStreamlabsSuccessComponent } from './dialog-notify/login-streamlabs-success/login-streamlabs-success.component';
import { NotifyRessetPassFailComponent } from './dialog-notify/notify-resset-pass-fail/notify-resset-pass-fail.component';
import { VerifyEmailConfirmComponent } from './dialog-notify/verify-email-confirm/verify-email-confirm.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { ConnectionInterceptor } from './shared/interceptor/connection-interceptor';
import { SharedModule } from './shared/shared.module';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import(
  /* webpackChunkName: "_locale-your-locale-js"*/
  /* webpackMode: "eager" */
  '@angular/common/locales/ja'
).then((m) => storeLocaleData(m.default, 'ja-JP'));
export const firebaseKey = {
  apiKey: "AIzaSyBOEPMQw6mQT0w8C2KzpTzcdW0pvbHFLX8",
  authDomain: "gipt-395204.firebaseapp.com",
  databaseURL: "https://gipt-395204-default-rtdb.firebaseio.com",
  projectId: "gipt-395204",
  storageBucket: "gipt-395204.appspot.com",
  messagingSenderId: "395732323816",
  appId: "1:395732323816:web:961e459726d7d06c2b285d",
  measurementId: "G-Y2F4NM9KLB"
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    SharedModule
  ],
  declarations: [
    AppComponent,
    VerifyEmailConfirmComponent,
    NotifyRessetPassFailComponent,
    LoginStreamlabsSuccessComponent,
  ],
  providers: [APP_ROUTE_PROVIDER,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectionInterceptor,
      multi: true
    },
    provideEnvironmentNgxCurrency({
      align: "right",
      allowNegative: true,
      allowZero: true,
      decimal: ".",
      precision: 0,
      prefix: "¥",
      suffix: "",
      thousands: ",",
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

