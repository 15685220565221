import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from '@proxy/users';
import { catchError } from 'rxjs';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-verify-email-confirm',
  templateUrl: './verify-email-confirm.component.html',
  styleUrls: ['./verify-email-confirm.component.scss']
})
export class VerifyEmailConfirmComponent extends AppComponentBase implements OnInit {
  id: string;
  visible = false;
  constructor(private _router: Router,
    private router: ActivatedRoute,
    private userManagerService: AppUserService,
    injector: Injector) {
    super(injector);
    if (history?.state && history?.state?.email) {
      this.id = history.state.id.replace(/"/g, '');
    }
  }
  ngOnInit(): void {
    this.userManagerService.verifyAccount(this.router.snapshot.paramMap.get('id'), this.router.snapshot.paramMap.get('code')).pipe(
      catchError(err => {
        this.visible = true;
        return null;
      })
    )
      .subscribe(res => {
        this._router.navigate(['/auth/sign-in'],{ state: { verified: true} });
      })
  }
}
