import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveLogoHeader {
  private dataSubject = new BehaviorSubject<any>(null);
  private urlSubject = new BehaviorSubject<any>(null);

  setUrlCurrent(url: any) {
    this.urlSubject.next(url);
  }

  getUrlCurrent(): Observable<any> {
    return this.urlSubject.asObservable();
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }
}
