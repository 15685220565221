<div *ngIf="isShow" class="row">
  <!-- Start: Mobile item -->
  <div class="col-12 mobile-view">
    <div class="mob-btn-close-dialog" (click)="closeDialog()">
      <img src="assets/images/icon/svg/close.svg" alt="circle times" />
    </div>

    <div class="d-flex align-items-center m-b-16">
      <img
        class="m-r-8 icon-title"
        src="assets/images/icon/svg/gift-box-icon.svg"
        alt="gift icon"
      />
      <span class="f-s-24 f-w-500 color-111">
        {{
          (this.dialogService?.data?.item?.id
            ? '::OrderManagement:EditGiftDetail'
            : '::OrderManagement:AddGiftDetail'
          ) | abpLocalization
        }}
      </span>
    </div>
  </div>
  <!-- End: Mobile item -->

  <div
    class="col-12 col-md-5 desktop-view desktop-gallery"
    [ngClass]="{ 'p-r-23': !isMobileScreen }"
  >
    <ng-container *ngIf="checkViewImg()">
      <p-galleria
        class="custom-thumbnail-pc"
        (activeIndexChange)="activeIndexChange('custom-thumbnail-pc')"
        [(value)]="images"
        (activeIndexChange)="activeIndexChange()"
        [autoPlay]="false"
        [numVisible]="3"
      >
        <ng-template pTemplate="item" let-item>
          <div class="loading-container">
            <img [src]="getImageFromUrl(item.itemSrc, widthType.W250)" *ngIf="!loadingData" alt="" />
          </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="row row-nogutter justify-content-center">
            <img *ngIf="!loadingData" [src]="getImageFromUrl(item.thumbnailSrc, widthType.W40)" alt="" />
          </div>
        </ng-template>
      </p-galleria>
    </ng-container>
    <div *ngIf="loadingData" class="loading-container">
      <div class="img-loading">
        <div class="images-loadding-center">
          <img src="/assets/images/icon/svg/loadding.svg" alt="" />
          <br />
          <label for="">Loading...</label>
        </div>
      </div>
    </div>
    <div *ngIf="checkEditImg()" class="img-view">
      <div class="img-center">
        <img
          class="mainImage-result"
          *ngIf="!loadingData"
          (error)="handleAddItemManuallyError($event)"
          [src]="getImageFromUrl(itemDetail?.mainImageUrl, widthType.W250)"
          alt=""
        />
        <div class="hover-mainImageUrl">
          <img
            *ngIf="checkEditImg()"
            (click)="mainImageUrl.click()"
            src="assets/images/icon/svg/add-img.svg"
            alt=""
          />
        </div>
      </div>
      <input
        [accept]="imageAccepts"
        class="hidden"
        type="file"
        name="mainImageUrl"
        #mainImageUrl
        (change)="onFileMainImageUrl($event)"
      />
    </div>
    <div *ngIf="checkEditImg()" class="img-thumbnail-customer">
      <div
        *ngFor="let item of arrSmallImagesUrl; let i = index"
        class="img-item-thumbnail"
        [ngClass]="{ isview: !checkEditImg() }"
      >
        <img
          *ngIf="i === 0 && checkAddImg()"
          (click)="smallImagesUrl.click()"
          class="img-add-default"
          [src]="item"
          alt=""
        />
        <img class="img-add" *ngIf="i !== 0" [src]="getImageFromUrl(item, widthType.W250)" alt="" />
        <img
          *ngIf="i !== 0 && checkEditImg()"
          class="hover-smallImagesUrl"
          (click)="removeSmallImagesUrl(i)"
          src="assets/images/icon/svg/delete-img-cover.svg"
          alt=""
        />
        <input
          [accept]="imageAccepts"
          class="hidden"
          type="file"
          name="smallImagesUrl"
          #smallImagesUrl
          (change)="onFileSmallImagesUrl($event)"
        />
      </div>
    </div>
  </div>
  <div class="col-12 col-md-7" [ngClass]="{ 'p-l-0': !isMobileScreen }">
    <form [formGroup]="formData" autocomplete="off">
      <dft-label-validation
        class="f-s-12 f-w-500 color-111"
        [title]="'::Wishlist:Creator:Detail:ProductName' | abpLocalization"
      >
      </dft-label-validation>
      <textarea
        maxlength="4000"
        class="w-100 custom-textarea"
        rows="2"
        cols="12"
        pInputTextarea
        formControlName="Title"
      ></textarea>
      <dft-validation [control]="formData.get('Title')"></dft-validation>
      <div class="text-end f-s-12 f-w-400 color-111 m-b-4">
        <span>{{ link }}</span>
      </div>
      <!-- Start: Mobile item -->
      <div
        class="m-t-10 m-b-16 mobile-view mobile-gallery-right"
        [ngClass]="{ 'custom-view': !loadingData }"
      >
        <ng-container *ngIf="checkViewImg()">
          <p-galleria
            [(value)]="images"
            [containerStyle]="{ 'max-width': '310px' }"
            [numVisible]="4"
            [showThumbnailNavigators]="false"
            thumbnailsPosition="right"
            verticalThumbnailViewPortHeight="245px"
          >
            <ng-template pTemplate="item" let-item>
              <div class="preview-img-custom">
                <img [src]="getImageFromUrl(item.itemSrc, widthType.W250)" alt="" />
              </div>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
              <div class="thumbnail-img-custom">
                <img [src]="getImageFromUrl(item.thumbnailSrc, widthType.W40)" alt="" />
              </div>
            </ng-template>
          </p-galleria>
        </ng-container>
        <div *ngIf="loadingData" class="loading-container-mobile">
          <div class="img-loading-mobile">
            <div class="images-loadding-center-mobile">
              <img src="/assets/images/icon/svg/loadding.svg" alt="" />
              <br />
              <label for="">Loading...</label>
            </div>
          </div>
        </div>
        <div *ngIf="checkEditImg()" class="img-view">
          <div class="img-center">
            <img
              class="mainImage-result"
              *ngIf="!loadingData"
              (error)="handleAddItemManuallyError($event)"
              [src]="getImageFromUrl(itemDetail?.mainImageUrl, widthType.W250)"
              alt=""
            />
            <div *ngIf="checkEditImg()" class="hover-mainImageUrl">
              <img (click)="mainImageUrl.click()" src="assets/images/icon/svg/add-img.svg" alt="" />
            </div>
          </div>
          <input
            [accept]="imageAccepts"
            class="hidden"
            type="file"
            name="mainImageUrl"
            #mainImageUrl
            (change)="onFileMainImageUrl($event)"
          />
        </div>
        <div *ngIf="checkEditImg()" class="img-thumbnail-customer">
          <div
            *ngFor="let item of arrSmallImagesUrl; let i = index"
            class="img-item-thumbnail"
            [ngClass]="{ isviewMobile: !checkEditImg() }"
          >
            <img
              *ngIf="i === 0 && checkEditImg()"
              (click)="smallImagesUrl.click()"
              class="img-add-default"
              [src]="item"
              alt=""
            />
            <img class="img-add" *ngIf="i !== 0" [src]="getImageFromUrl(item, widthType.W40)" alt="" />
            <img
              *ngIf="i !== 0 && checkEditImg()"
              class="hover-smallImagesUrl"
              (click)="removeSmallImagesUrl(i)"
              src="assets/images/icon/svg/delete-img-cover.svg"
              alt=""
            />
            <input
              [accept]="imageAccepts"
              class="hidden"
              type="file"
              name="smallImagesUrl"
              #smallImagesUrl
              (change)="onFileSmallImagesUrl($event)"
            />
          </div>
        </div>
      </div>
      <!-- End: Mobile item -->
      <div class="row">
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-11 p-r-8': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:ProductUnitPrice' | abpLocalization"
          >
          </dft-label-validation>
          <input
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            (change)="onInputProductUnitPrice($event)"
            maxlength="12"
            currencyMask
            formControlName="ProductUnitPrice"
            class="p-inputtext"
            />
          <dft-validation [control]="formData.get('ProductUnitPrice')"></dft-validation>
        </div>
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-8 p-r-11': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:Quantity' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <p-inputNumber
              (change)="onInputQuantity($event)"
              min="1"
              max="10"
              class="w-100 custom-quantity"
              [style]="{ width: '100%' }"
              formControlName="Quantity"
              locale="en-US"
            >
            </p-inputNumber>
            <dft-validation [control]="formData.get('Quantity')"></dft-validation>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 m-b-8" [ngClass]="{ 'p-l-11 p-r-8': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:Postage' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <input
              (change)="onInputPostage($event)"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="12"
              currencyMask
              formControlName="Postage"
              class="p-inputtext"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6" [ngClass]="{ 'p-l-8 p-r-11': !isMobileScreen }">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111"
            [title]="'::Wishlist:Creator:Detail:TotalProductAmount' | abpLocalization"
          >
          </dft-label-validation>
          <div>
            <input
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              currencyMask
              formControlName="TotalProductAmount"
              class="p-inputtext"
            />
          </div>
        </div>
        <div class="col-12">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111 m-t-8"
            [title]="'::CreatorDonate:Profile:AddWishListMannually:Remarks' | abpLocalization"
          >
          </dft-label-validation>
          <textarea
            placeholder="{{
              '::CreatorDonate:Profile:AddWishListMannually:RemarksPlaceholder' | abpLocalization
            }}"
            maxlength="4000"
            class="w-100 custom-textarea"
            rows="2"
            cols="12"
            pInputTextarea
            formControlName="Remarks"
          ></textarea>
        </div>
        <div class="col-12">
          <dft-label-validation
            class="f-s-12 f-w-500 color-111 m-t-8"
            [title]="'::Wishlist:Creator:Detail:Message' | abpLocalization"
          >
          </dft-label-validation>
          <div class="textarea-item-detail-custom">
            <textarea pInputTextarea formControlName="message" maxlength="100"></textarea>
          </div>
        </div>
      </div>

      <div class="row note">
        <div class="text-note col-12 col-sm-12">
          <span>{{ '::CreatorDonate:Profile:AddWishListMannually:Note1' | abpLocalization }} </span>
          <br />
          <span>
            {{ '::CreatorDonate:Profile:AddWishListMannually:Note2' | abpLocalization }}
          </span>
        </div>
      </div>
      <!-- Btn save hiểm thị khi ở trạng thái created và isEdit = true -->
      <!-- Hoặc ở màn thêm mới sp # amazon -->
      <p-button
        *ngIf="(this.giftStatusDetail === giftStatus.Created && isEdit) || (!isView && !isEdit)"
        [loading]="loading"
        [disabled]="loadingData || loading"
        (click)="saveData()"
        styleClass="p-button-rounded  w-100 button-content m-b-8"
      >
        <span class="me-2 f-s-13 f-w-500">
          {{
            !isView
              ? ('::Wishlist:Creator:Detail:Addition' | abpLocalization)
              : ('::OrderManagement:ButtonSave' | abpLocalization)
          }}
        </span>
      </p-button>
      <p-button (click)="closeDialog()" styleClass="custom-btn-cancel w-100">
        <span class="me-2 f-s-14 f-w-500 color-111">{{
          '::Wishlist:Creator:Detail:Cancel' | abpLocalization
        }}</span>
      </p-button>
    </form>
  </div>
</div>
