import type { AccountActiveDto, AppResetPasswordDto, AppVerifyPasswordResetTokenInput, ChangePassInput, CreatorForViewDto, CreatorProfileSettingDto, GetListCreatorInputDto, InforByPostCode, LookupTableDto, UserRegisterDto } from './dtos/models';
import type { CurrentUserDto } from './models';
import type { UserType } from './user-type.enum';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { VideoDirectionType } from '../videos/video-direction-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  apiName = 'Default';
  

  changePassByInput = (input: ChangePassInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/change-pass',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkAccountActive = (input: UserRegisterDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountActiveDto>({
      method: 'POST',
      url: '/api/app/app-user/check-account-active',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkAccountIsExits = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/check-account-is-exits',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  checkAccountNameIsExistByInput = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/check-account-name-is-exist',
      params: { input },
    },
    { apiName: this.apiName,...config });
  

  checkLogInForTheFirstTime = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/check-log-in-for-the-first-time',
    },
    { apiName: this.apiName,...config });
  

  checkResetPassword = (input: AppVerifyPasswordResetTokenInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/check-reset-password',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkUserHasLoggedIn = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/check-user-has-logged-in',
    },
    { apiName: this.apiName,...config });
  

  deleteAccount = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/app-user/account',
    },
    { apiName: this.apiName,...config });
  

  deleteImgCover = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: '/api/app/app-user/img-cover',
    },
    { apiName: this.apiName,...config });
  

  getCreatorInforSetting = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreatorProfileSettingDto>({
      method: 'GET',
      url: '/api/app/app-user/creator-infor-setting',
    },
    { apiName: this.apiName,...config });
  

  getCurrentUserId = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, CurrentUserDto>({
      method: 'GET',
      url: '/api/app/app-user/current-user-id',
    },
    { apiName: this.apiName,...config });
  

  getInforByPrefecturesByInput = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, InforByPostCode>({
      method: 'GET',
      url: '/api/app/app-user/infor-by-prefectures',
      params: { input },
    },
    { apiName: this.apiName,...config });
  

  getListCreator = (input: GetListCreatorInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CreatorForViewDto>>({
      method: 'GET',
      url: '/api/app/app-user/creator',
      params: { filter: input.filter, sort: input.sort, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getPrefecturesByPostCodeByInput = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableDto[]>({
      method: 'GET',
      url: '/api/app/app-user/prefectures-by-post-code',
      params: { input },
    },
    { apiName: this.apiName,...config });
  

  getProfileCurrentUser = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreatorProfileSettingDto>({
      method: 'GET',
      url: '/api/app/app-user/profile-current-user',
    },
    { apiName: this.apiName,...config });
  

  getUserProvider = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/app-user/user-provider',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  getUserType = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserType>({
      method: 'GET',
      url: '/api/app/app-user/user-type',
    },
    { apiName: this.apiName,...config });
  

  isAdmin = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/is-admin',
    },
    { apiName: this.apiName,...config });
  

  logOut = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/log-out',
    },
    { apiName: this.apiName,...config });
  

  logoutStreamlabs = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/logout-streamlabs',
    },
    { apiName: this.apiName,...config });
  

  reSendMail = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/re-send-mail',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  register = (input: UserRegisterDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/app-user/register',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  resetPassword = (input: AppResetPasswordDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/reset-password',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  sendResetPasswordLink = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/app-user/send-reset-password-link',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  updateCoverImage = (coverImage: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/app-user/cover-image',
      params: { coverImage },
    },
    { apiName: this.apiName,...config });
  

  updateCreatorInforSetting = (input: CreatorProfileSettingDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: '/api/app/app-user/creator-infor-setting',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updateInforDonatedSuccess = (messageDonated: string, imageDonated: string, imageDonatedPath: string, audioDonated: string, audioDonatedPath: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/app-user/infor-donated-success',
      params: { messageDonated, imageDonated, imageDonatedPath, audioDonated, audioDonatedPath },
    },
    { apiName: this.apiName,...config });
  

  updateProfileImage = (profileImage: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/app-user/profile-image',
      params: { profileImage },
    },
    { apiName: this.apiName,...config });
  

  updateResetVideoShort = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/app-user/reset-video-short',
    },
    { apiName: this.apiName,...config });
  

  updateShortVideo = (shortVideo: string, videoDirectionType: VideoDirectionType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/app/app-user/short-video',
      params: { shortVideo, videoDirectionType },
    },
    { apiName: this.apiName,...config });
  

  updateStreamlabToken = (tokenStl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/app-user/streamlab-token',
      params: { tokenStl },
    },
    { apiName: this.apiName,...config });
  

  updateUserType = (userType: UserType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/app-user/user-type',
      params: { userType },
    },
    { apiName: this.apiName,...config });
  

  verifyAccount = (id: string, code: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/app-user/${id}/verify-account`,
      params: { code },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
