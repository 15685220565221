import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwipeService {
  private messageSource = new BehaviorSubject("");
  private currentSwipe = new BehaviorSubject<any>(null);

  currentMessage = this.messageSource.asObservable();
  currentSwipeId = this.currentSwipe.asObservable();


  setDirection(message: string) {
    this.messageSource.next(message);
  }
  public swipeNext() {
    this.setDirection("next");
  }
  public swipePrevious() {
    this.setDirection("previous");
  }

  setSwipeId(id: any) {
    this.currentSwipe.next(id);
  }
}
