<div class="row">
    <p-dialog position="top" [closable]="false" [(visible)]="visible" [breakpoints]="{ '960px': '358px' }"
        [style]="{ width: '526px' , heigth:'240px'}" [draggable]="false" [modal]="true" [resizable]="false"
        styleClass="custom-dialog">
        <div>
            <img src="assets/images/icon/svg/warning.svg" alt="">
            <h3>
                {{'::VerifyEmail:ErrorMessage1'|abpLocalization}}
            </h3>
            <p>
                {{'::VerifyEmail:ErrorMessage2'|abpLocalization}}
            </p>
        </div>
    </p-dialog>
</div>