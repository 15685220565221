<div class="carousel-custom">
  <div class="carousel-custom-container">
    <div
      id="carousel-{{ carouselId }}"
      class="carousel-custom-items-container"
    >
      <div
        *ngFor="let item of itemSample; let i = index"
        class="carousel-custom-item"
        (touchstart)="swipe($event, 'start', carouselId)"
        (touchend)="swipe($event, 'end', carouselId)"
      >
        <div class="carousel-custom-item-img p-x-{{ item.px_mb }}">
          <img *ngIf="item?.img_top" class="img-top" [src]="item?.img_top" alt="" />
          <img *ngIf="item?.img_mid" class="img-mid" [src]="item?.img_mid" alt="" />
          <img *ngIf="item?.img_bot" class="img-bot" [src]="item?.img_bot" alt="" />

          <img *ngIf="item?.img" class="img" [src]="item?.img" alt="" />

          <img *ngIf="item?.img_center" class="img-center" [src]="item?.img_center" alt="" />
        </div>
        <div class="carousel-custom-item-title">
          {{ item.title1 | abpLocalization }}
          <br />
          {{ item.title2 | abpLocalization }}
        </div>
        <div class="carousel-custom-item-text">
          {{ item.content1 | abpLocalization }}
        </div>
      </div>
    </div>
  </div>

  <div class="carousel-custom-indicators">
    <span
      *ngFor="let indicator of itemArray; let i = index"
      class="carousel-custom-indicators-item"
      [ngClass]="{ active: i === currentIndicator }"
    ></span>
  </div>
</div>
