import type { CreateWishListDto, CreatorInfoForWishListViewDto, CreatorInfoForWishListViewWithoutCityDto, DayInput, DonationRankingInputDto, DonationRankingOutputDto, GetWishListCreatorViewDto, UpdateWishListDto, WishListForViewDto } from './dtos/models';
import type { CreateWishListMalymoonDto, UpdateWishListMalymoonDto } from './dtos/wist-list-malymoon/models';
import type { CreateWishListMannuallyDto, EcsiteInput, ProductInforWishListManuallyDto } from './dtos/wist-list-manually/models';
import type { RankingOrderType } from './ranking-order-type.enum';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetListGiftBoxViewOutputDto } from '../carts/dtos/models';
import type { LookupTableIntDto } from '../global/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class WishListCreatorViewService {
  apiName = 'Default';
  

  acceptGiftById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-creator-view/${id}/accept-gift`,
    },
    { apiName: this.apiName,...config });
  

  addWishList = (input: CreateWishListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/wish-list',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  addWishListEcsite = (input: CreateWishListMannuallyDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/wish-list-ecsite',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  addWishListMalymoon = (input: CreateWishListMalymoonDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/wish-list-malymoon',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  cancelGiftById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-creator-view/${id}/cancel-gift`,
    },
    { apiName: this.apiName,...config });
  

  changeStatusToCancelById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/wish-list-creator-view/${id}/change-status-to-cancel`,
    },
    { apiName: this.apiName,...config });
  

  checkShowHideRankings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/check-show-hide-rankings',
    },
    { apiName: this.apiName,...config });
  

  checkUserInHonshuOrShikoku = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/check-user-in-honshu-or-shikoku',
    },
    { apiName: this.apiName,...config });
  

  deleteWishList = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/wish-list-creator-view/${id}/wish-list`,
    },
    { apiName: this.apiName,...config });
  

  getAllDonationRanking = (type: RankingOrderType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DonationRankingOutputDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/donation-ranking',
      params: { type },
    },
    { apiName: this.apiName,...config });
  

  getCreatorInfo = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreatorInfoForWishListViewWithoutCityDto>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/creator-info',
    },
    { apiName: this.apiName,...config });
  

  getDayInMonth = (input: DayInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableIntDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/day-in-month',
      params: { year: input.year, month: input.month },
    },
    { apiName: this.apiName,...config });
  

  getInforFromListCreatorsByCreatorIds = (creatorIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreatorInfoForWishListViewDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/infor-from-list-creators',
      params: { creatorIds },
    },
    { apiName: this.apiName,...config });
  

  getListDelivered = (input: GetWishListCreatorViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/delivered',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListGiftBox = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetListGiftBoxViewOutputDto>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/gift-box',
    },
    { apiName: this.apiName,...config });
  

  getListProcessing = (input: GetWishListCreatorViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/processing',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListShipped = (input: GetWishListCreatorViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/shipped',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListWishList = (input: GetWishListCreatorViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/wish-list',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getMonth = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableIntDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/month',
    },
    { apiName: this.apiName,...config });
  

  getPrefectures = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableIntDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/prefectures',
    },
    { apiName: this.apiName,...config });
  

  getPublishTheBirth = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableIntDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/publish-the-birth',
    },
    { apiName: this.apiName,...config });
  

  getWishListEcsiteForEdit = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'GET',
      url: `/api/app/wish-list-creator-view/${id}/wish-list-ecsite-for-edit`,
    },
    { apiName: this.apiName,...config });
  

  getWishListForEdit = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'GET',
      url: `/api/app/wish-list-creator-view/${id}/wish-list-for-edit`,
    },
    { apiName: this.apiName,...config });
  

  getYear = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LookupTableIntDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-creator-view/year',
    },
    { apiName: this.apiName,...config });
  

  importWishlist = (link: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DonationRankingInputDto>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/import-wishlist',
      params: { link },
    },
    { apiName: this.apiName,...config });
  

  importWishlistBackgroundJobs = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-creator-view/${id}/import-wishlist-background-jobs`,
    },
    { apiName: this.apiName,...config });
  

  postProductDetailFromAmazonByInput = (input: EcsiteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/product-detail-from-amazon',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postProductDetailFromMalymoonByInput = (input: EcsiteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'POST',
      url: '/api/app/wish-list-creator-view/product-detail-from-malymoon',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updateWishList = (id: string, input: UpdateWishListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/wish-list-creator-view/${id}/wish-list`,
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updateWishListEcsite = (id: string, input: CreateWishListMannuallyDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/wish-list-creator-view/${id}/wish-list-ecsite`,
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updateWishListMalymoon = (id: string, input: UpdateWishListMalymoonDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/wish-list-creator-view/${id}/wish-list-malymoon`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
