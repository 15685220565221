import type { CheckAmazonPriceDto, CreatorInfoForWishListViewWithoutCityDto, DonationRankingInputDto, DonationRankingOutputDto, GetWishListFanViewDto, ItemDetailForFanViewDto, PriceStatusOutputDto, WishListForViewDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AddGiftToCartInputDto, GetListGiftBoxViewOutputDto, GiftInCartViewDto, UpdateCartInfoInputDto, UpdateCartMessageInputDto } from '../carts/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class WishListFanViewService {
  apiName = 'Default';
  

  addGiftToCart = (input: AddGiftToCartInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/wish-list-fan-view/gift-to-cart',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkGiftIsDeleted = (ids: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/wish-list-fan-view/check-gift-is-deleted',
      body: ids,
    },
    { apiName: this.apiName,...config });
  

  checkGiftPriceFromAmazonAndGiftByInput = (input: CheckAmazonPriceDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PriceStatusOutputDto>({
      method: 'POST',
      url: '/api/app/wish-list-fan-view/check-gift-price-from-amazon-and-gift',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkGiftPriceFromAmazonByGiftId = (giftId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PriceStatusOutputDto>({
      method: 'POST',
      url: `/api/app/wish-list-fan-view/check-gift-price-from-amazon/${giftId}`,
    },
    { apiName: this.apiName,...config });
  

  checkShowHideRankings = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/wish-list-fan-view/check-show-hide-rankings/${userId}`,
    },
    { apiName: this.apiName,...config });
  

  deteleteCart = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-fan-view/${id}/detelete-cart`,
    },
    { apiName: this.apiName,...config });
  

  followCreator = (giptCreatorId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-fan-view/follow-creator/${giptCreatorId}`,
    },
    { apiName: this.apiName,...config });
  

  getAllDonationRanking = (input: DonationRankingInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DonationRankingOutputDto[]>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/donation-ranking',
      params: { userId: input.userId, type: input.type },
    },
    { apiName: this.apiName,...config });
  

  getCreatorIdsFromGiftIdsByGiftIds = (giftIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/creator-ids-from-gift-ids',
      params: { giftIds },
    },
    { apiName: this.apiName,...config });
  

  getCreatorInfo = (giptCreatorId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreatorInfoForWishListViewWithoutCityDto>({
      method: 'GET',
      url: `/api/app/wish-list-fan-view/creator-info/${giptCreatorId}`,
    },
    { apiName: this.apiName,...config });
  

  getGiftInCart = (giftId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GiftInCartViewDto>({
      method: 'GET',
      url: `/api/app/wish-list-fan-view/gift-in-cart/${giftId}`,
    },
    { apiName: this.apiName,...config });
  

  getItemDetail = (giftId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ItemDetailForFanViewDto>({
      method: 'GET',
      url: `/api/app/wish-list-fan-view/item-detail/${giftId}`,
    },
    { apiName: this.apiName,...config });
  

  getListDelivered = (input: GetWishListFanViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/delivered',
      params: { giptCreatorId: input.giptCreatorId, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListGiftBox = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetListGiftBoxViewOutputDto>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/gift-box',
    },
    { apiName: this.apiName,...config });
  

  getListGiftBoxWhenCheckoutBox = (isCheckAmazon: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetListGiftBoxViewOutputDto>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/gift-box-when-checkout-box',
      params: { isCheckAmazon },
    },
    { apiName: this.apiName,...config });
  

  getListPackingInProgress = (input: GetWishListFanViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/packing-in-progress',
      params: { giptCreatorId: input.giptCreatorId, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListShipped = (input: GetWishListFanViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/shipped',
      params: { giptCreatorId: input.giptCreatorId, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListWishList = (input: GetWishListFanViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<WishListForViewDto>>({
      method: 'GET',
      url: '/api/app/wish-list-fan-view/wish-list',
      params: { giptCreatorId: input.giptCreatorId, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  unFollowCreator = (giptCreatorId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/wish-list-fan-view/un-follow-creator/${giptCreatorId}`,
    },
    { apiName: this.apiName,...config });
  

  updateCartInfo = (input: UpdateCartInfoInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/wish-list-fan-view/cart-info',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updateCartMessage = (input: UpdateCartMessageInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/wish-list-fan-view/cart-message',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
