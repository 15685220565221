import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppUserService } from '@proxy/users';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-login-streamlabs-success',
  template: '',
})
export class LoginStreamlabsSuccessComponent extends AppComponentBase implements OnInit {
    /*
    // 0. call lên streamlab hiện tại đang gọi đến url backend => chuyển url của openId:
    I.Sau khi login bằng streamlabs xong:
      1. Frontend (streamlab) nhận được streamlabaccesstoken từ query string của url
      2. Frontend (tab streamlab gửi streamlabaccessstoken lên backend
      3. Backend cập nhật streamlabaccesstoken vào User
      4. Backend gọi Hub để báo cho Frontend (tab profile setting) đã login streamlab thành công
      5. Frontend (streamlab) tự đóng tab
    */
  constructor(private route: ActivatedRoute, private _userService: AppUserService, injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
    let streamlabtoken = this.route.snapshot.queryParams['streamlabtoken'];
    if(streamlabtoken){
      this._userService.updateStreamlabToken(streamlabtoken).subscribe(res => {
        window.close();
      });
    }else{
      this.showErrorMessage(this.L('::CreatorDonate:Profile:Streamlabs:Login:Fail'));
      window.close();
    }
  }
}
