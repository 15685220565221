import { Component } from '@angular/core';

@Component({
  selector: 'app-notify-resset-pass-fail',
  templateUrl: './notify-resset-pass-fail.component.html',
  styleUrls: ['./notify-resset-pass-fail.component.scss']
})
export class NotifyRessetPassFailComponent {

}
