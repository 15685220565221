import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUserService } from '@proxy/users';
import { FooterChangeImgServiceService } from '../main/_layout/services/footer-change-img.service';
import { AppComponentBase } from '../shared/app-component-base';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AppComponentBase implements OnInit, OnDestroy {
  giftToltal = 0;
  isMobileScreen = false;
  isLoad = false;

  imgLogo = 'assets/images/logo/logo.svg';
  boxItems = [
    [
      {
        id: 0,
        img_top: 'assets/images/home/box-one-1.png',
        img_mid: 'assets/images/home/polygon.svg',
        img_bot: 'assets/images/home/box-one-share.png',
        title1: '::Home:Main:BoxOne1stTitle01',
        title2: '::Home:Main:BoxOne1stTitle02',
        content1: '::Home:Main:BoxOne1stText',
        px_mb: '30',
        py_mb: '20',
      },
      {
        id: 1,
        img_top: 'assets/images/home/box-one-2.png',
        img_mid: 'assets/images/home/polygon.svg',
        img_bot: 'assets/images/home/box-one-share.png',
        title1: '::Home:Main:BoxOne2ndTitle01',
        title2: '::Home:Main:BoxOne2ndTitle02',
        content1: '::Home:Main:BoxOne2ndText',
        px_mb: '30',
        py_mb: '20',
      },
      {
        id: 2,
        img: 'assets/images/home/section-one-1.png',
        title1: '::Home:Main:BoxOne3rdTitle01',
        title2: '::Home:Main:BoxOne3rdTitle02',
        content1: '::Home:Main:BoxOne3rdText',
        px_mb: '20',
        py_mb: '60',
      },
    ],
    [
      {
        id: 0,
        img: 'assets/images/home/section-two-2.png',
        title1: '::Home:Main:BoxTwo1stTitle01',
        title2: '::Home:Main:BoxTwo1stTitle02',
        content1: '::Home:Main:BoxTwo1stText',
        px_mb: '16',
        py_mb: '72',
      },
      {
        id: 1,
        img: 'assets/images/home/streamlabs.png',
        title1: '::Home:Main:BoxTwo2ndTitle01',
        title2: '::Home:Main:BoxTwo2ndTitle02',
        content1: '::Home:Main:BoxTwo2ndText',
        px_mb: '59',
        py_mb: '170',
      },
      {
        id: 2,
        img: 'assets/images/home/section-two-3.png',
        title1: '::Home:Main:BoxTwo3rdTitle01',
        title2: '::Home:Main:BoxTwo3rdTitle02',
        content1: '::Home:Main:BoxTwo3rdText',
        px_mb: '48',
        py_mb: '72',
      },
    ],
    [
      {
        id: 0,
        img: 'assets/images/home/box-three-1.png',
        img_center: 'assets/images/logo/logo.svg',
        title1: '::Home:Main:BoxThree1stTitle01',
        title2: '::Home:Main:BoxThree1stTitle02',
        content1: '::Home:Main:BoxThree1stText',
        px_mb: '35',
        py_mb: '158',
      },
      {
        id: 1,
        img: 'assets/images/home/section-three-1.png',
        title1: '::Home:Main:BoxThree2ndTitle01',
        title2: '::Home:Main:BoxThree2ndTitle02',
        content1: '::Home:Main:BoxThree2ndText',
        px_mb: '24',
        py_mb: '103',
      },
      {
        id: 2,
        img: 'assets/images/home/box-three-3.png',
        title1: '::Home:Main:BoxThree3rdTitle01',
        title2: '::Home:Main:BoxThree3rdTitle02',
        content1: '::Home:Main:BoxThree3rdText',
        px_mb: '43',
        py_mb: '78',
      },
    ],
  ];

  iconsBrand = [
    {
      listIcon: [
        { id: 0, icon: 'assets/images/home/adidas.svg' },
        { id: 1, icon: 'assets/images/home/amazon.svg' },
        { id: 2, icon: 'assets/images/home/apple.svg' },
        { id: 3, icon: 'assets/images/home/burberry.svg' },
        { id: 4, icon: 'assets/images/home/chanel.svg' },
      ],
    },
    {
      listIcon: [
        { id: 0, icon: 'assets/images/home/calvin_klein.svg' },
        { id: 1, icon: 'assets/images/home/dyson.svg' },
        { id: 2, icon: 'assets/images/home/h&m.svg' },
        { id: 3, icon: 'assets/images/home/nike.svg' },
        { id: 4, icon: 'assets/images/home/nintendo.svg' },
      ],
    },
    {
      listIcon: [
        { id: 0, icon: 'assets/images/home/samsung.svg' },
        { id: 1, icon: 'assets/images/home/sony.svg' },
        { id: 2, icon: 'assets/images/home/rakuten.svg' },
        { id: 3, icon: 'assets/images/home/zara.svg' },
      ],
    },
  ];

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 575;
    this.handlePlayVideoLP();
  }

  constructor(
    injector: Injector,
    private router: Router,
    private _userService: AppUserService,
    private footerChangeImgServiceService: FooterChangeImgServiceService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.isLoad = true;
    this.getScreenSize();

    setTimeout(() => {
      this.isLoad = false;
    }, 1500);
    this.footerChangeImgServiceService.setData(this.screenType.default);
  }

  ngOnDestroy(): void {
    this.footerChangeImgServiceService.setData(this.screenType.cross);
  }

  login() {
    this.router.navigate(['/auth/sign-in']);
  }

  handlePlayVideoLP() {
    setTimeout(() => {
      if (this.isMobileScreen) {
        const videoSP = document.getElementById('video-bg-sp') as HTMLVideoElement;
        this.playPromise(videoSP);

        const bgSP = document.getElementById('img-bg-sp');
        const communitySP = document.getElementById('img-community-sp');
        let videoSPPlay = videoSP?.paused;

        if (videoSPPlay) {
          bgSP?.classList?.remove('d-none');
          communitySP?.classList?.remove('d-none');
        } else {
          bgSP?.classList?.add('d-none');
          communitySP?.classList?.add('d-none');
        }
      } else {
        const video = document.getElementById('video-bg') as HTMLVideoElement;
        this.playPromise(video);
      }
      const videoCommunity = document.getElementById('video-community') as HTMLVideoElement;
      this.playPromise(videoCommunity);
    });
  }

  private playPromise(element: any) {
    if (element) {
      const playPromise = element.play();

      if (playPromise) {
        playPromise
          .then(() => {
            element.muted = true;
            element.play();
          })
          .catch(() => {
            element.muted = true;
            element.play();
          });
      }
    }
  }
}
