import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateWishListDto, GiftImageDto, UpdateWishListDto } from '@proxy/wish-list/dtos/models';
import { Subject, catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { WishListCreatorViewService } from '@proxy/wish-list/wish-list-creator-view.service';
import { AddWhishlistService } from '../../services/add-whishlist.service';
import { GiftStatus } from '@proxy/gifts/gift-status.enum';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import {
  ChoicesManuallyDto,
  EcsiteInput,
  VariantManuallyDto,
} from '@proxy/wish-list/dtos/wist-list-manually/models';
import { CommonComponent } from 'src/app/shared/dft/component/common.component';
import { ValidationComponent } from 'src/app/shared/dft/component/validation-messages.component';
import { ProductType } from '@proxy/gifts/product-type.enum';

@Component({
  selector: 'app-item-detail-creator',
  templateUrl: './item-detail-creator.component.html',
  styleUrls: ['./item-detail-creator.component.scss', '../../_layout.scss'],
})
export class ItemDetailCreatorComponent
  extends AppComponentBase
  implements OnInit, AfterViewChecked
{
  ref: DynamicDialogRef | undefined;
  formData: FormGroup;
  itemDetail: any;
  images = [];
  loading = false;
  itemListOption: FormArray;
  onAdd = false;
  isView = false;
  isEdit = false;
  isDisAbled = false;
  isShow = false;
  asin = '';
  link = '';
  loadingData = false;
  dataRespneError = false;
  tabIndex = 0;
  edit = false;
  isMobileScreen = false;
  giftStatus = GiftStatus;
  giftStatusDetail: any;
  variations = [];
  typeUrlGift: ProductType;

  private quantitySub$ = new Subject<any>();
  private postageSub$ = new Subject<any>();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 767;
  }
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public dialog: DialogService,
    public wishListCreatorViewService: WishListCreatorViewService,
    private dialogService: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private addWhishlistService: AddWhishlistService,
    injector: Injector,
    private fb: FormBuilder
  ) {
    super(injector);
  }
  ngAfterViewChecked(): void {
    this.replaceImg('assets/images/icon/svg/dropdown.svg', '.custom-dropdown .p-icon-wrapper');
  }
  replaceImg(src: string, className: string) {
    const buttonElement = document.querySelectorAll(className);
    if (buttonElement) {
      buttonElement.forEach(element => {
        const newImg = document.createElement('img');
        newImg.src = src;
        const svgElement = element.querySelector('svg');
        if (svgElement && newImg) {
          svgElement.replaceWith(newImg);
        }
      });
    }
  }

  ngOnInit(): void {
    let el = document.querySelector<HTMLElement>('.p-dialog');
    el.style.boxShadow = 'unset';
    this.getScreenSize();
    setTimeout(() => {
      this.isShow = true;
    }, 1);
    this.initForm();
    this.onAdd = true;
    this.tabIndex = this.dialogService.data.isEdit;
    if (this.dialogService.data.isEdit !== 0) {
      this.isEdit = true;
      this.isDisAbled = true;
      this.formData.disable();
    } else {
      this.formData.enable();
      this.isEdit = false;
      this.isDisAbled = false;
    }
    this.isView = this.dialogService.data.isView;
    if (this.isView) {
      this.getDataEdit(this.dialogService.data.id);
    } else {
      let input: EcsiteInput = {
        url: this.dialogService.data.item,
        variations: [],
      };
      this.getData(input);
    }
  }

  initForm() {
    this.formData = this.fb.group({
      Title: [undefined, Validators.required],
      ProductUnitPrice: [
        undefined,
        Validators.compose([Validators.required, ValidationComponent.ValidateNumberMin1]),
      ],
      Quantity: [undefined, Validators.required],
      Variation: [undefined],
      Color: [undefined],
      Postage: [undefined],
      TotalProductAmount: [undefined],
      Remarks: [undefined],
      message: [undefined],
      opTions: this.fb.array([]),
    });
    // , { updateOn: 'blur' }
    this.itemListOption = this.formData.get('opTions') as FormArray;
    this.formData.controls.Quantity.valueChanges
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(valueChange => {
        if (valueChange) {
          if (valueChange > 10) {
            this.formData.get('Quantity')?.setValue(10);
            return;
          }
          this.checkShipping();
        }
      });
    this.formData.controls.Postage.valueChanges.pipe(debounceTime(350)).subscribe(s => {
      this.setTotalProductAmount();
    });
    this.formData.controls.ProductUnitPrice.valueChanges.pipe(debounceTime(350)).subscribe(s => {
      this.setTotalProductAmount();
    });
  }

  onChangePostage(event: any) {
    const numericValue = this.convertPriceStringToNum(event.target.value);
    this.formData.get('Postage')?.setValue(numericValue);
  }

  onInputQuantity(event: any) {
    this.quantitySub$.next(event);
  }

  onChangeQuantity(event: any) {
    let numericValue = this.convertPriceStringToNum(event.target.value);
    if (numericValue) {
      if (numericValue > 10) numericValue = 10;
      setTimeout(() => this.formData.get('Quantity')?.setValue(numericValue));
    }
  }

  checkShipping() {
    const s = this.formData.controls.Quantity.value;
    if (s !== null && s !== undefined && s !== '') {
      this.wishListCreatorViewService.checkUserInHonshuOrShikoku().subscribe(rs => {
        if (this.formData.controls.ProductUnitPrice.value != null) {
          const total = s * this.formData.controls.ProductUnitPrice.value;
          if (total > 3500) {
            this.formData.controls.Postage.setValue(0);
          } else {
            const shipFee = rs ? 410 : 450;
            this.formData.controls.Postage.setValue(shipFee);
          }
        }
        this.setTotalProductAmount();
      });
    }
  }

  getDataEdit(id: any) {
    this.formData.disable();
    this.loadingData = true;
    this.wishListCreatorViewService
      .getWishListForEdit(id)
      .pipe(
        finalize(() => {
          this.onAdd = false;
          this.loadingData = false;
          this.giftStatusDetail = this.itemDetail.status;
          if (this.itemDetail.status === GiftStatus.Queued) {
            this.isDisAbled = true;
            this.formData.disable();
          } else if (this.itemDetail.status === GiftStatus.Created) {
            this.formData.enable();
            this.isDisAbled = false;
          }
          this.setValueEdit();
        })
      )
      .subscribe(rs => {
        this.itemDetail = rs;
        let image: GiftImageDto = {
          itemSrc: rs.mainImageUrl,
          thumbnailSrc: rs.mainImageUrl,
          isMain: true,
          order: 0,
        };
        this.images.push(image);
        rs.smallImagesUrl.forEach(element => {
          let i = 1;
          const image: GiftImageDto = {
            itemSrc: element,
            thumbnailSrc: element,
            isMain: false,
            order: i,
          };
          i++;
          this.images.push(image);
        });
        this.itemDetail.variations.forEach(rsOptions => {
          let arrValue = [];
          rsOptions.choices.forEach(element => {
            arrValue.push({
              id: element.text,
              displayName: element.text,
              disabled: element.disabled,
              selected: element.selected,
            });
          });
          if (this.onAdd) {
            this.addOption(rsOptions.name, arrValue);
          }
        });
      });
  }

  getData(input: any) {
    this.formData.disable();
    this.images = [];
    this.dataRespneError = false;
    this.loadingData = true;
    this.wishListCreatorViewService
      .postProductDetailFromAmazonByInput(input)
      .pipe(
        catchError(err => {
          this.handlerError(err);
          this.dataRespneError = true;
          return err;
        }),
        finalize(() => {
          this.onAdd = false;
          this.loadingData = false;
          this.setTotalProductAmount();
          this.itemListOption.controls.forEach((element: FormControl) => {
            this.enableFormArray(element);
            this.isDisAbled = false;
          });
          this.formData.enable();
          this.setValueAdd();
        })
      )
      .subscribe((rs: any) => {
        this.itemDetail = rs;
        if (
          this.itemDetail.isInStock === null ||
          this.itemDetail.isInStock === false ||
          this.itemDetail.isInStock === undefined
        ) {
          this.showErrorMessage(this.L('::CreatorDonate:CannotGetQuantity'));
        }
        let image: GiftImageDto = {
          itemSrc: rs.mainImageUrl,
          thumbnailSrc: rs.mainImageUrl,
          isMain: true,
          order: 0,
        };
        this.images.push(image);
        rs.smallImagesUrl.forEach(element => {
          let i = 1;
          const image: GiftImageDto = {
            itemSrc: element,
            thumbnailSrc: element,
            isMain: false,
            order: i,
          };
          i++;
          this.images.push(image);
        });
        this.variations = [];
        this.itemDetail.variations.forEach(rsOptions => {
          let chois: ChoicesManuallyDto[] = [];
          chois.push(rsOptions.choices.find(s => s.selected === true));
          let variation: VariantManuallyDto = {
            name: rsOptions.name,
            choices: chois,
          };
          this.variations.push(variation);
          let arrValue = [];
          rsOptions.choices.forEach(element => {
            arrValue.push({
              id: element.text,
              displayName: element.text,
              disabled: element.disabled,
              selected: element.selected,
            });
          });
          if (this.onAdd) {
            this.addOption(rsOptions.name, arrValue);
          } else {
            const formArrayIndex = this.itemListOption.controls.findIndex(
              controlGroup => controlGroup.get(rsOptions.name) != null
            );
            if (formArrayIndex !== -1) {
              const formGroup = this.itemListOption.at(formArrayIndex) as FormGroup;
              const formControl = formGroup.get('arrValue') as FormControl;
              formControl.setValue(arrValue);
            } else {
              console.log('Không tìm thấy FormControl với tên ' + rsOptions.name);
            }
          }
        });
      });
  }

  setTotalProductAmount() {
    const total = Math.round(
      this.formData.controls.ProductUnitPrice.value * this.formData.controls.Quantity.value +
        this.formData.controls.Postage.value +
        this.formData.controls.ProductUnitPrice.value * this.formData.controls.Quantity.value * 0.07
    );
    this.formData.controls.TotalProductAmount.setValue(total);
  }
  get itemOption() {
    return this.formData.get('opTions') as FormArray;
  }
  addOption(name: any, array: any): void {
    const optionsArray = this.formData.get('opTions') as FormArray;
    optionsArray.push(this.createOption(name, array));
  }
  createOption(name, array): FormGroup {
    let a = this.fb.group({
      [name]: [name],
      arrValue: [array],
    });
    const option = a.get(name) as FormControl;
    a.get(name).setValue(array.find(rs => rs.selected === true)?.id);
    let selectedOption = array.find(rs => rs.selected === true)?.id;
    if (this.isEdit) {
      this.disableFormArray(option);
    }
    if (!selectedOption) {
      this.showErrorMessage(this.L('::CreatorDonate:Profile:SomeItemsHaveNotBeenSet'));
    }

    return a;
  }
  disableFormArray(formControl: FormControl) {
    formControl.disable();
  }
  enableFormArray(formControl: FormControl) {
    formControl.enable();
  }
  filterOption(event: any, formControlName: any) {
    this.itemListOption.controls.forEach((element: FormControl) => {
      this.disableFormArray(element);
      this.isDisAbled = true;
    });
    this.formData.disable();
    if (this.itemDetail.variations) {
      const formControlNameVariations = this.itemDetail.variations.find(
        w => w.name === formControlName
      );
      if (formControlNameVariations) {
        const choice = formControlNameVariations.choices.find(s => s.text === event.value);
        if (choice) {
          choice.selected = true;
        }
        const choices = formControlNameVariations.choices.filter(s => s.text !== event.value);
        if (choices.length > 0) {
          choices.forEach(element => {
            element.selected = false;
          });
        }
        const input: EcsiteInput = {
          url: this.itemDetail.url,
          variations: this.itemDetail.variations,
        };
        this.getData(input);
      }
    }
  }

  getcontrols(form: FormGroup) {
    const controlKeys = Object.keys(form.controls);
    form.controls[controlKeys[0]].setValidators(Validators.required);
    form.controls[controlKeys[0]].updateValueAndValidity();
    return controlKeys[0] || null;
  }
  setValueEdit() {
    this.formData.controls.Title.setValue(this.itemDetail.name);
    this.formData.controls.ProductUnitPrice.setValue(this.itemDetail.price);
    this.formData.controls.ProductUnitPrice.disable();
    this.formData.controls.Quantity.setValue(this.itemDetail.quantity, { emitEvent: false });
    this.formData.controls.Postage.setValue(this.itemDetail.shipFee);
    this.formData.controls.Remarks.setValue(this.itemDetail.description);
    this.formData.controls.message.setValue(this.itemDetail.message);
    this.formData.controls.TotalProductAmount.disable();
    this.link = this.itemDetail.url;
  }
  setValueAdd() {
    this.formData.controls.Quantity.setValue(1);
    this.formData.controls.Title.setValue(this.itemDetail.name);
    this.formData.controls.ProductUnitPrice.setValue(this.itemDetail.price);
    this.formData.controls.ProductUnitPrice.disable();
    this.formData.controls.TotalProductAmount.disable();
    this.formData.controls.Postage.setValue(this.itemDetail.shipFee);
    this.link = this.itemDetail.url;
  }

  closeDialog(): void {
    // Truyền dữ liệu khi đóng dialog
    this.dialogRef.close(false);
  }
  saveData(): void {
    if (CommonComponent.getControlErr(this.formData) === '') {
      this.loading = true;
      setTimeout(() => {
        if (!this.isView) {
          const input: CreateWishListDto = {
            url: this.itemDetail.url,
            name: this.formData.controls.Title.value.trim(),
            quantity: this.formData.controls.Quantity.value,
            shipFee: this.formData.controls.Postage.value,
            smallImagesUrl: this.itemDetail.smallImagesUrl,
            mainImageUrl: this.itemDetail.mainImageUrl,
            price: this.formData.controls.ProductUnitPrice.value,
            category: '',
            description: this.formData.controls.Remarks.value,
            message: this.formData.controls.message.value,
            itemSelected: JSON.stringify(this.variations),
          };
          this.wishListCreatorViewService
            .addWishList(input)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                if (
                  this.itemDetail.isInStock === null ||
                  this.itemDetail.isInStock === false ||
                  this.itemDetail.isInStock === undefined
                ) {
                  this.showErrorMessage(this.L('::CreatorDonate:CannotGetQuantity'));
                }
                return err;
              }),
              finalize(() => {
                this.loading = false;
              })
            )
            .subscribe(res => {
              this.dialogRef.close(true);
              this.addWhishlistService.setData(true);
              this.showSuccessMessage(this.L('::Wishlist:AddWishList:SuccessfullyAdded'));
            });
        } else {
          const input: UpdateWishListDto = {
            url: this.itemDetail.url,
            name: this.formData.controls.Title.value.trim(),
            quantity: this.formData.controls.Quantity.value,
            shipFee: this.formData.controls.Postage.value,
            smallImagesUrl: this.itemDetail.smallImagesUrl,
            mainImageUrl: this.itemDetail.mainImageUrl,
            price: this.formData.controls.ProductUnitPrice.value,
            category: '',
            description: this.formData.controls.Remarks.value,
            message: this.formData.controls.message.value,
            itemSelected: JSON.stringify(this.variations),
          };
          this.wishListCreatorViewService
            .updateWishList(this.dialogService.data.id, input)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                return err;
              }),
              finalize(() => {
                this.loading = false;
              })
            )
            .subscribe(res => {
              this.dialogRef.close(true);
              this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyUpdated'));
            });
        }
      }, 500);
    } else {
      this.showErrorMessage(this.L('::CreatorDonate:Profile:SomeItemsHaveNotBeenSet'));
    }
  }

  delete() {
    let contentStyle = {
      overflow: 'auto',
      padding: '60px 80px',
      borderRadius: '20px',
    };

    if (this.isMobileScreen) {
      contentStyle = {
        overflow: 'auto',
        padding: '24px',
        borderRadius: '8px',
      };
    }

    this.ref = this.dialog.open(ConfirmDeleteComponent, {
      width: '526px',
      contentStyle: contentStyle,
      baseZIndex: 10000,
      maximizable: false,
      showHeader: false,
      dismissableMask: false, // click ra ngoài để tắt dialog
    });
    this.ref.onClose.subscribe(dataReturn => {
      if (dataReturn.data) {
        this.wishListCreatorViewService
          .deleteWishList(this.dialogService.data.item.id)
          .pipe(
            catchError(err => {
              this.handlerError(err);
              return err;
            }),
            finalize(() => {})
          )
          .subscribe(res => {
            this.dialogRef.close(true);
            this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyDeleted'));
          });
      } else {
        return null;
      }
    });
  }

  activeIndexChange(className: string) {
    setTimeout(() => {
      const classActive = '.' + className + ' .p-galleria-thumbnail-items';
      const element = this.elementRef.nativeElement.querySelector(classActive);
      if (element) {
        this.renderer.setStyle(element, 'transform', 'translate3d(0%, 0px, 0px)'); // Thay đổi giá trị transform tùy ý
      }
    }, 1);
  }
}
