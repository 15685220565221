import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFilm,
  faBars,
  faAngleDown,
  faUser,
  faCog,
  faGift,
} from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { GalleriaModule } from 'primeng/galleria';
import { DialogService } from 'primeng/dynamicdialog';
import { InputText, InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputMaskModule } from 'primeng/inputmask';
import { CarouselModule } from 'primeng/carousel';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  imports: [
    ButtonModule,
    ToastModule,
    CheckboxModule,
    PasswordModule,
    DialogModule,
    MenubarModule,
    BadgeModule,
    MenuModule,
    FontAwesomeModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    TagModule,
    TooltipModule,
    InputNumberModule,
    InputTextareaModule,
    SelectButtonModule,
    TabMenuModule,
    PaginatorModule,
    SidebarModule,
    ProgressBarModule,
    GalleriaModule,
    InputTextModule,
    TabViewModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    InputMaskModule,
    CarouselModule,
    AccordionModule,
    InputSwitchModule
  ],
  exports: [
    ButtonModule,
    ToastModule,
    CheckboxModule,
    PasswordModule,
    DialogModule,
    MenubarModule,
    BadgeModule,
    MenuModule,
    FontAwesomeModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    TagModule,
    TooltipModule,
    InputNumberModule,
    InputTextareaModule,
    SelectButtonModule,
    TabMenuModule,
    PaginatorModule,
    SidebarModule,
    ProgressBarModule,
    GalleriaModule,
    InputTextModule,
    TabViewModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    InputMaskModule,
    CarouselModule,
    AccordionModule,
    InputSwitchModule
  ],
  providers: [MessageService, DialogService],
})
export class PrimengSharedModule {
  constructor(library: FaIconLibrary) {
    // library.addIcons(faFilm, faBars, faAngleDown, faUser, faCog, faGift);
    library.addIconPacks(fas, far, fab);
  }
}
