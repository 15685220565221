/* eslint-disable */
import { LocalizationService } from '@abp/ng.core';
import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: '<dft-validation>',
  template: `
    <div class="valid-height text-start">
      <div *ngIf="control?.dirty">
        <span style="font-size: 13px !important; color: #DC3545;">
          {{ message }}
        </span>
      </div>
    </div>
  `,
})
export class ValidationComponent {
  @Input() control;
  constructor(private localizationService: LocalizationService) {}
  get message() {
    try {
      if (this.control.errors) {
        for (const err in this.control.errors) {
          if (this.control.errors.hasOwnProperty(err)) {
            return this.getErrorMessage(err);
          }
        }
      }
    } catch (error) {}
  }

  public static maxlength =
    (length = 0): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      if (control?.value?.trim().length > length) {
        return { maxlength: { requiredLength: length } };
      }
      return null;
    };

  public static minlength =
    (length = 0): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      if (control?.value?.trim().length > 0 && control?.value?.trim().length < length) {
        return { minlength: { requiredLength: length } };
      }
      return null;
    };

  public static maxNumber =
    (max = 0, type: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      if ((control?.value || `${control?.value}` === '0') && +control.value > max) {
        return { maxNumber: { value: max, type: type } };
      }
      return null;
    };

  public static minNumber =
    (min = 0, type: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      if ((control?.value || `${control?.value}` === '0') && +control.value < min) {
        return { minNumber: { value: min, type: type } };
      }
      return null;
    };

  public static required(control: AbstractControl) {
    if (`${control?.value}` === '0') {
      return null;
    }

    if (!control?.value) {
      return { required: true };
    }
    if (typeof control?.value === 'string' && control?.value?.trim().length === 0) {
      return { required: true };
    }
    return null;
  }
  public static requiredEmail(control: AbstractControl) {
    if (`${control?.value}` === '0') {
      return null;
    }

    if (!control?.value) {
      return { requiredEmail: true };
    }
    if (typeof control?.value === 'string' && control?.value?.trim().length === 0) {
      return { requiredEmail: true };
    }
    return null;
  }

  public static checkPass(control: AbstractControl) {
    try {
      if (control.value != null) {
        if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/.test(control.value)) {
          return { pass: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  public static checkPassworkConfirm(
    pass: string,
    conf: string,
    err: object = { confirmPass: true }
  ) {
    return (c: AbstractControl) => {
      let passW = c.parent.controls[pass];
      let conF = c.parent.controls[conf];
      if (conF.value !== undefined) {
        if (passW.value !== conF.value) {
          return err;
        } else {
          return null;
        }
      }
      return null;
    };
  }

  public static checkEmail(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(trimmedValue)) {
          return { email: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  public static MatchPassword(control: AbstractControl) {
    try {
      if (
        control.get('ConfirmPass').errors &&
        !control.get('ConfirmPass').errors.newPasswordDoNotMatchConfirmPassword
      ) {
        return null;
      }
      let password = control.get('Pass').value;
      let confirmPassword = control.get('ConfirmPass').value;
      if (password != confirmPassword) {
        control.get('ConfirmPass').setErrors({ newPasswordDoNotMatchConfirmPassword: true });
      } else {
        return control.get('ConfirmPass').setErrors(null);
      }
    } catch (error) {}
  }

  public static checkUrlAmazonJp(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (
          /^(https:\/\/www\.amazon\.co\.jp\/|https:\/\/amazon\.co\.jp\/)+[^\s]+\/dp\/[A-Z0-9]+\/?/i.test(
            trimmedValue
          ) ||
          /^https:\/\/amazon\.co\.jp\/dp\/[A-Z0-9]+?/i.test(trimmedValue) ||
          /^https:\/\/www\.amazon\.co\.jp\/dp\/[A-Z0-9]+?/i.test(trimmedValue)
        ) {
          return null;
        } else {
          return { malformedurl: true };
        }
      }
      return null;
    } catch (error) {}
  }
  public static formatNameSNS(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^[a-zA-Z0-9_\-\.]+$/.test(trimmedValue)) {
          return { formatNameSNS: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  public static formatNameYoutubeSNS(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^[\p{L}\p{N}_\-\.]+$/u.test(trimmedValue)) {
          return { formatNameSNS: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {
      // Handle the error if necessary
    }
  }

  public static formatAcountName(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^[a-zA-Z0-9_\-\.]+$/.test(trimmedValue)) {
          return { formatAccountName: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  public static ValidateNumberMin1(control: AbstractControl) {
    try {
      if (control.value <= 0) {
        return { minimumDonation1: true };
      }
      return null;
    } catch (error) {}
  }
  public static formatPostCode(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^\d{3}-\d{4}$/.test(trimmedValue)) {
          return { formatPostCode: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }
  public static formatUrl(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(trimmedValue)) {
          return { formatUrl: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  public static checkSpace(control: AbstractControl) {
    try {
      if (control.value != null && control.value != '') {
        const trimmedValue = control.value.trim(); // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
        if (trimmedValue.length === 0) {
          return { required: true };
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {}
  }

  getErrorMessage(err) {
    const messages = {
      required: this.localizationService.instant('::Validation:FieldIsRequired'),
      pass: this.localizationService.instant('::CreatorDonate:AppUser:00004'),
      confirmPass: this.localizationService.instant('::CreatorDonate:AppUser:00010'),
      email: this.localizationService.instant('::CreatorDonate:AppUser:00005'),
      requiredEmail: this.localizationService.instant('::CreatorDonate:AppUser:00001'),
      requiredEndDate: this.localizationService.instant('::Validation:RequiredEndDate'),
      malformedurl: this.localizationService.instant('::Wishlist:AddWishList:MalformedUrl'),
      lengthPhone: this.localizationService.instant('::CreatorDonate:Profile:FormatPhone'),
      formatNameSNS: this.localizationService.instant('::CreatorDonate:Profile:FormatNameSNS'),
      formatPostCode: this.localizationService.instant('::CreatorDonate:Profile:FormatPostCode'),
      formatUrl: this.localizationService.instant('::CreatorDonate:Profile:FormatUrl'),
      newPasswordDoNotMatchConfirmPassword: this.localizationService.instant(
        '::CreatorDonate:Profile:NewPasswordDoNotMatchConfirmPassword'
      ),
      acountNameIsExist: this.localizationService.instant(
        '::CreatorDonate:Profile:AcountNameIsExist'
      ),
      postCodeNotExist: this.localizationService.instant(
        '::CreatorDonate:Profile:PostCodeNotExist'
      ),
      formatAccountName: this.localizationService.instant(
        '::CreatorDonate:Profile:FormatAccountName'
      ),
      minimumDonation: this.localizationService.instant('::Validation:MinimumDonation'),
      maximumDonation: this.localizationService.instant('::Validation:MaximumDonation'),
      minimumDonation1: this.localizationService.instant('::Validation:MinimumDonation1'),
      checkSpace: this.localizationService.instant('::Validation:FieldIsRequired'),
      selectYearPls: this.localizationService.instant('::Validation:SelectYearPls'),
      selectMonthPls: this.localizationService.instant('::Validation:SelectMonthPls'),
    };
    return messages[err];
  }
}
