<div class="confirm-donate-price-wrap">
  <img src="assets/images/icon/svg/warning.svg" alt="">
  <br>
  <!-- <div >
    <label for=""></label>
  </div> -->
  <div class="text-title color-111 f-s-16 f-w-700 m-t-8 m-b-8">
    {{'::Wishlist:FanView:Donate:ConfirmDonateTitle' | abpLocalization}}
  </div>
  <div class="text-sub-title color-A9A9A9 f-s-12 f-w-500 m-b-32">
    {{'::Wishlist:FanView:Donate:ConfirmDonateSubTitle' |
    abpLocalization:transformPrice(data.priceOld):transformPrice(data.priceNew)}}
  </div>

  <p-button (click)="closeDialog(data.priceNew)" styleClass="w-100 m-b-8">
    <span class="f-w-500">{{'::Wishlist:GiftBox:PayFull' | abpLocalization}}</span>
    <span class="f-w-100 m-l-24">¥{{data.priceNew | tienTe}}</span>
  </p-button>
  <p-button (click)="closeDialog(data.priceOld)" styleClass="p-button-outlined w-100 m-b-8">
    <span class="f-w-500">{{'::Wishlist:GiftBox:PayPart' | abpLocalization}}</span>
    <span class="f-w-100 m-l-24">¥{{data.priceOld | tienTe}}</span>
  </p-button>
  <p-button (click)="closeDialog(null)" styleClass="custom-btn-cancel w-100">
    <span class="f-w-500 color-111">
      {{'::Wishlist:GiftBox:ChangeAmount' | abpLocalization}}
    </span>
  </p-button>
</div>
