import { Component, Injector, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent extends AppComponentBase implements OnInit {
  constructor(
    private dialogService: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
    let el = document.querySelector<HTMLElement>('.p-dialog');
    el.style.boxShadow = 'unset';
  }
  

  closeDialog(data): void {
    this.dialogRef.close({ data: data });
  }
}
