import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ConnectionInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone({
            withCredentials: true
        });
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    window.document.location.reload();
                }

                if (!navigator.onLine) {
                    // Xử lý lỗi khi mất kết nối tới server
                    console.log('Mất kết nối tới server');
                    // Có thể hiển thị thông báo lỗi cho người dùng
                    alert('サーバーエラー。後でもう一度お試しください');
                }
                return throwError(error);
            })
        );
    }
}
