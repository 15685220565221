<div class="not-found-wrap">
  <header class="header-wrap d-flex align-items-center justify-content-between">
      <a routerLink="/home" class="logo">
        <img class="logo" src="./assets/images/logo/logo1.svg" alt="" />
      </a>
      <div>
        <a routerLink="/auth/sign-in" class="btn-signin color-111">
          {{ '::SignIn:LoginButton' | abpLocalization }}
        </a>
        <a routerLink="/auth/sign-up" class="btn-signup color-fff">
          {{ '::SignIn:SignUpLable' | abpLocalization }}
        </a>
      </div>
  </header>
  <div class="not-found-header">
    <div class="not-found-header-title">
      <h4 class="color-fff f-w-500">{{ '::CreatorDonate:404:Title' | abpLocalization }}</h4>
      <p class="color-fff f-w-500">{{ '::CreatorDonate:404:NotFound' | abpLocalization }}</p>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-12 text-content-title text-start">
        <label for="">{{ '::CreatorDonate:404:NotGetPage' | abpLocalization }}</label>
      </div>
      <div class="col-12 text-content-content text-start m-t-12">
        <label for="">{{ '::CreatorDonate:404:PageNotExist' | abpLocalization }}</label>
      </div>
    </div>

  </div>
</div>
<app-footer [screen]="'home'"></app-footer>