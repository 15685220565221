import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GiftBoxService {
  private dataSubject = new BehaviorSubject<any>(null);
  private dataRemoveSubject = new BehaviorSubject<any>(null);

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  setDataRemove(items: any, id: any) {
    const data = {
      id: id,
      giptCreatorId: items.giptCreatorId,
    }
    this.dataRemoveSubject.next(data);
  }

  getDataRemove(): Observable<any> {
    return this.dataRemoveSubject.asObservable();
  }
}
