import { mapEnumToOptions } from '@abp/ng.core';

export enum GiftStatus {
  Created = 0,
  Deleted = 1,
  Queued = 2,
  Processing = 3,
  Confirmed = 4,
  Issue = 5,
  Hold = 6,
  Ordered = 7,
  Shipped = 8,
  Delivered = 9,
  Cancel = 10,
  Returning = 11,
  Returned = 12,
  Refunded = 13,
  Failed = 14,
  Disputed = 15,
  Draft = 16,
  All = -1,
}

export const giftStatusOptions = mapEnumToOptions(GiftStatus);
